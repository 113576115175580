import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useGetLeadingPlayerMatchSeasonTotalsQuery } from 'common/store/modules/api/statsPro-api';
import { LastRoundHighsSlider } from './LastRoundHighsSlider';

export const SeasonHighDataFetcher = ({
    isActive,
    seasonPid,
    isAFLW,
    title
}) => {
    const { data } = useGetLeadingPlayerMatchSeasonTotalsQuery(seasonPid);

    return (
        <div
            className={classNames(
                'last-round-highs__slider stats-slider fade-in-on-load',
                {
                    'is-loaded': !!data,
                    'is-active': isActive
                }
            )}
        >
            <LastRoundHighsSlider
                data={data}
                type={'season'}
                isAFLW={isAFLW}
                title={title}
            />
        </div>
    );
};

SeasonHighDataFetcher.propTypes = {
    isActive: PropTypes.bool.isRequired,
    seasonPid: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool,
    title: PropTypes.string
};
