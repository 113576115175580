import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    getTranslation,
    lookupTranslation
} from 'common/react/utils/translations';
import { ToggleInput } from 'common/react/components/ToggleInput';
import { useGetRoundsQuery } from 'common/store/modules/api/afl-api';
import { findLastRound } from './utils/findLastRound';
import { RoundHighDataFetcher } from './components/RoundHighDataFetcher';
import { SeasonHighDataFetcher } from './components/SeasonHighDataFetcher';

export const LastRoundHighs = ({
    seasonId,
    compseasonId,
    competitionPid,
    currentRound,
    roundSubtitle,
    seasonSubtitle
}) => {
    const [activeSlider, setActiveSlider] = useState('round');
    const toggleActiveSlider = (type) => setActiveSlider(type);
    const translations = {
        lastRound: getTranslation('label.statspro.lastRound'),
        round: getTranslation('label.statspro.round'),
        highs: getTranslation('label.statspro.highs'),
        season: getTranslation('label.statspro.season')
    };

    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = competitionPid === WOMENS;

    const { lastRound } = useGetRoundsQuery(
        {
            compseasonId: compseasonId,
            params: {
                pageSize: 50
            }
        },
        {
            selectFromResult: ({ data }) => ({
                lastRound: data?.length
                    ? findLastRound(data, parseInt(currentRound))
                    : null
            })
        }
    );

    return (
        <>
            <div className={'last-round-highs__header'}>
                <div className={'last-round-highs__toggle'}>
                    <span className={'last-round-highs__toggle-divider'}></span>
                    <ToggleInput
                        id="data-type-toggle"
                        value={activeSlider}
                        onChange={(type) => toggleActiveSlider(type)}
                        options={[
                            {
                                label: translations.lastRound,
                                value: 'round'
                            },
                            {
                                label: translations.season,
                                value: 'season'
                            }
                        ]}
                    />
                    <span className={'last-round-highs__toggle-divider'}></span>
                </div>
                <div
                    className={classNames(
                        'last-round-highs__heading-container widget-header',
                        {
                            'is-active': activeSlider === 'round'
                        }
                    )}
                >
                    <div className="widget-header__content">
                        <h2
                            className={
                                'last-round-highs__title widget-header__title'
                            }
                        >
                            {lookupTranslation('label.statspro.roundHighs', {
                                roundName: lastRound?.name || ''
                            })}
                        </h2>
                        {roundSubtitle !== '' && (
                            <p className={'last-round-highs__subtitle'}>
                                {roundSubtitle}
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className={classNames(
                        'last-round-highs__heading-container widget-header',
                        {
                            'is-active': activeSlider === 'season'
                        }
                    )}
                >
                    <div className="widget-header__content">
                        <h2
                            className={
                                'last-round-highs__title widget-header__title'
                            }
                        >
                            {translations.season} {translations.highs}
                        </h2>
                        {seasonSubtitle !== '' && (
                            <p
                                className={
                                    'last-round-highs__subtitle widget-header__subtitle'
                                }
                            >
                                {seasonSubtitle}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {lastRound?.providerId && activeSlider === 'round' ? (
                <RoundHighDataFetcher
                    isActive={activeSlider === 'round'}
                    roundPid={lastRound?.providerId}
                    isAFLW={isAFLW}
                    title={lookupTranslation('label.statspro.roundHighs', {
                        roundName: lastRound?.name
                    })}
                />
            ) : activeSlider === 'season' ? (
                <SeasonHighDataFetcher
                    isActive={activeSlider === 'season'}
                    seasonPid={seasonId}
                    isAFLW={isAFLW}
                    title={`${translations.season} ${translations.highs}`}
                />
            ) : null}
        </>
    );
};

LastRoundHighs.propTypes = {
    seasonId: PropTypes.string.isRequired,
    compseasonId: PropTypes.string.isRequired,
    competitionPid: PropTypes.string.isRequired,
    currentRound: PropTypes.string.isRequired,
    roundSubtitle: PropTypes.string,
    seasonSubtitle: PropTypes.string,
    roundName: PropTypes.string
};
