import React from 'react';
import PropTypes from 'prop-types';

import {
    STATS_COLUMNS,
    STATS_COLUMN_IDS
} from 'widgets/statspro/js/components/common/config/columns';

const STATS_TO_SHOW = [
    STATS_COLUMN_IDS.DISPOSALS,
    STATS_COLUMN_IDS.GOALS,
    STATS_COLUMN_IDS.CLEARANCES,
    STATS_COLUMN_IDS.TACKLES
];

const CLASS_BLOCK = 'player-stats-tooltip-related-stats';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @param {object} props.player - Player.
 * @param {object} [props.benchmark] - Benchmark.
 * @returns {JSX.Element} Component.
 */
export const TooltipStats = (props) => {
    return (
        <ul className={CLASS_BLOCK}>
            {props.matchesPerRound.length > 1 && (
                <li
                    className={`${CLASS_BLOCK}__row ${CLASS_BLOCK}__row--highlighted`}
                >
                    <span className={`${CLASS_BLOCK}__name`}>Match Votes</span>
                    {props.matchesPerRound.map((match) => {
                        const foundVotes = props.roundByRoundVotes?.find(
                            (round) => round.matchId === match.matchId
                        );
                        return (
                            <span key={`${match.matchId}_votes`}>
                                {match.played === false
                                    ? '-'
                                    : foundVotes?.votes || '0'}
                            </span>
                        );
                    })}
                </li>
            )}

            {STATS_TO_SHOW.map((stat) => {
                return (
                    <li key={stat} className={`${CLASS_BLOCK}__row`}>
                        <span className={`${CLASS_BLOCK}__name`}>
                            {STATS_COLUMNS[stat].name}
                        </span>

                        {props.matchesPerRound.map((match) => {
                            return (
                                <span key={`${match.matchId}_${stat}`}>
                                    {match.stats && match.stats[stat]
                                        ? match.stats[stat]
                                        : '-'}
                                </span>
                            );
                        })}
                    </li>
                );
            })}
        </ul>
    );
};

TooltipStats.propTypes = {
    row: PropTypes.object.isRequired,
    cell: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    rounds: PropTypes.object.isRequired,
    matchesPerRound: PropTypes.array,
    roundByRoundVotes: PropTypes.array
};

const StatListItem = ({ name, value }) => {
    return (
        <li className={`${CLASS_BLOCK}__item`}>
            <span className={`${CLASS_BLOCK}__name`}>{name}</span>
            <span className={`${CLASS_BLOCK}__value`}>{value}</span>
        </li>
    );
};

StatListItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};
