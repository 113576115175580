import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_NAV_ITEM } from 'store/modules/brownlow-tracker/round-by-round/actions';
import { roundByRoundNavSelector } from 'store/modules/brownlow-tracker/round-by-round/selectors/nav-selector';
import { FETCH_MATCH_BY_MATCH_DATA } from 'store/modules/brownlow-tracker/match-by-match/actions';
import { latestAnnouncedRound } from 'store/modules/brownlow-tracker/match-by-match/selectors';

export const useRoundByRoundNavSync = ({
    competitions,
    seasons,
    defaultSeason,
    seasonsToShow,
    competitionId
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(roundByRoundNavSelector);

    const roundsList = useSelector(
        (state) => state.rounds[`season_${nav.season.id}`]?.list
    );
    const currentLatestRound = useSelector((state) =>
        latestAnnouncedRound(state, nav.season.providerId, roundsList)
    );

    const { filteredSeasons, setDefaultSeason } = useMemo(() => {
        const seasonsToShowArray = seasonsToShow
            ? seasonsToShow.replace(/ /g, '').split(',')
            : '';

        return {
            filteredSeasons: seasons
                ? seasons.filter((season) => {
                      return seasonsToShowArray.includes(season.providerId);
                  })
                : [],
            setDefaultSeason:
                seasons?.find(
                    (season) => season.providerId === defaultSeason
                ) || {}
        };
    }, [defaultSeason, seasons, seasonsToShow]);

    /**
     * Set initial nav values
     */
    useEffect(() => {
        if (competitions && !nav.competition.id) {
            if (competitionId) {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'competition',
                        value: { id: competitionId }
                    })
                );
            } else {
                const { PREMIERSHIP } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
                const aflPremiershipCompetition = competitions.find(
                    (comp) => comp.providerId === PREMIERSHIP
                );

                if (aflPremiershipCompetition) {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'competition',
                            value: { id: aflPremiershipCompetition.id }
                        })
                    );
                }
            }
        }

        if (seasons && !nav.season.id) {
            if (Object.keys(setDefaultSeason).length !== 0) {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: {
                            id: setDefaultSeason.id,
                            providerId: setDefaultSeason.providerId
                        }
                    })
                );
            } else {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: {
                            id: filteredSeasons.length
                                ? filteredSeasons[0].id
                                : seasons[0].id,
                            providerId: filteredSeasons.length
                                ? filteredSeasons[0].providerId
                                : seasons[0].providerId
                        }
                    })
                );
            }
        }
    }, [
        competitions,
        dispatch,
        filteredSeasons,
        nav.competition.id,
        nav.season.id,
        seasons,
        setDefaultSeason
    ]);

    /**
     * Whenever the season changes, start fetching votes data and add to the
     * store so we can figure out the first round to display
     */
    useEffect(() => {
        if (nav.season.providerId) {
            dispatch(
                FETCH_MATCH_BY_MATCH_DATA.request({
                    seasonPid: nav.season.providerId
                })
            );

            // Reset the round so the latest round with votes can be selected
            if (seasons) {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'round',
                        value: null
                    })
                );
            }
        }
    }, [dispatch, nav.season.providerId]);

    /**
     * When there is a new roundList, set the nav item to the latest round with
     * votes
     */
    useEffect(() => {
        if (roundsList && nav.round === null && currentLatestRound !== null) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'round',
                    value: currentLatestRound
                })
            );
        }
    }, [dispatch, nav.round, roundsList, currentLatestRound]);
};
