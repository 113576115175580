import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, EffectCoverflow, Navigation } from 'swiper';
import { getTranslation } from 'common/react/utils/translations';
import { LastRoundHighsSlide } from './LastRoundHighsSlide';
import { SvgIcon } from 'common/react/components/SvgIcon';

SwiperCore.use([EffectCoverflow]);

const EVENTS = PULSE.app.common.CONSTANTS.TRACKING.EVENTS;

/**
 * @param {object} props - Props.
 * @param {string} props.category - Player category.
 * @param {Array} props.players - Player details.
 * @param {string} props.type - Category of slider.
 * @param {string} props.isAFLW - isAFLW
 * @returns {JSX.Element} Component.
 */
export const LastRoundHighsVerticalSlider = ({
    category,
    players,
    type,
    isAFLW
}) => {
    const translations = {
        jointLeaders: getTranslation('label.statspro.jointLeaders')
    };

    const [touchesDiff, setTouchesDiff] = useState(0);

    return (
        <div>
            <div className="last-round-highs__joint-leaders">
                {players.length + ' '}
                <span className="last-round-highs__joint-leaders-text">
                    {translations.jointLeaders}
                </span>
            </div>
            <div className="stats-slider-vertical__navigation">
                <button
                    className={`stats-slider-vertical__button-prev swiper-button-prev-unique-${category}`}
                ></button>
                <button
                    className={`stats-slider-vertical__button-next swiper-button-next-unique-${category}`}
                ></button>
                <SvgIcon
                    icon="action-button"
                    className="icon action-button"
                    folder="elements"
                    subfolder="stats"
                />
            </div>
            <Swiper
                nested={true}
                effect="coverflow"
                coverflowEffect={{
                    stretch: 192,
                    rotate: 0,
                    slideShadows: false
                }}
                direction="vertical"
                loop={true}
                slidesPerView="auto"
                centeredSlides={true}
                grabCursor={true}
                className="stats-slider-vertical"
                modules={[Navigation, A11y]}
                navigation={{
                    prevEl: '.swiper-button-prev-unique-' + category,
                    nextEl: '.swiper-button-next-unique-' + category
                }}
                speed={200}
                breakpoints={{
                    840: {
                        coverflowEffect: {
                            stretch: 253
                        }
                    }
                }}
                /* eslint-disable camelcase */
                onRealIndexChange={(swiperCore) => {
                    const eventType =
                        swiperCore.touches.diff &&
                        swiperCore.touches.diff !== touchesDiff
                            ? EVENTS.CAROUSEL.TYPE.SWIPE
                            : EVENTS.CAROUSEL.TYPE.ARROW;

                    const direction =
                        swiperCore.activeIndex > swiperCore.previousIndex
                            ? EVENTS.CAROUSEL.DIRECTION.NEXT
                            : EVENTS.CAROUSEL.DIRECTION.PREVIOUS;

                    PULSE.app.tracking.recordEvent(EVENTS.CAROUSEL.EVENT_NAME, {
                        eventData: {
                            component_name:
                                category + ' ' + translations.jointLeaders,
                            component_content_type:
                                EVENTS.CAROUSEL.CONTENT_TYPE.PLAYER,
                            count: swiperCore.loopedSlides,
                            index: swiperCore.realIndex + 1,
                            type: eventType,
                            direction: direction
                        }
                    });

                    setTouchesDiff(swiperCore.touches.diff);
                }}
            >
                {players.map((player, i) => (
                    <SwiperSlide key={i}>
                        <LastRoundHighsSlide
                            category={category}
                            players={[player]}
                            type={type}
                            isAFLW={isAFLW}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

LastRoundHighsVerticalSlider.propTypes = {
    category: PropTypes.string.isRequired,
    players: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    isAFLW: PropTypes.bool
};
