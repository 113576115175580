import React from 'react';
import PropTypes from 'prop-types';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';

/**
 * @param {object} props - Props.
 * @param props.children
 * @param props.tooltipContent
 * @param props.shouldHidePredicted
 * @param props.seasonPid
 * @returns {JSX.Element} Component.
 */
export const StatsSectionInfoButton = ({
    tooltipContent,
    shouldHidePredicted,
    seasonPid
}) => {
    return (
        <div className="filter-button stats-leaders-nav__filter-button stats-leaders-nav__filter-button--info">
            <TooltipButton
                id={`late-changes-disclamer`}
                title={getTranslation('label.legend')}
                direction="below"
                template={tooltipContent}
                primaryTooltipContent={'abc'}
                showOnHover={false}
                hideArrow={false}
                noTransform={true}
                theme={'light'}
                shouldHidePredicted={shouldHidePredicted}
                seasonPid={seasonPid}
            >
                <SvgIcon icon={`info-circle`} className="filter-button__icon" />
            </TooltipButton>
        </div>
    );
};

StatsSectionInfoButton.defaultProps = {
    name: 'StatsSectionInfoButton'
};

StatsSectionInfoButton.propTypes = {
    tooltipContent: PropTypes.string,
    shouldHidePredicted: PropTypes.bool,
    seasonPid: PropTypes.string
};
