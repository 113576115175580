/**
 * Combines leaderboard data, all matches and rounds to create a standarized shape of the leaderboard items
 *
 * @param {Array} leaderboard
 * @param {Array} allMatches
 * @param {Array} rounds
 * @returns {Array} Leadeboard with all matches
 */
export const getLeaderboardWithAllMatches = (
    leaderboard = [],
    allMatches = [],
    rounds = []
) => {
    const formattedLeaderboard = [];

    leaderboard.forEach((player) => {
        const teamId = player.team.teamId;

        const teamMatches = allMatches.filter(
            (match) =>
                match.away.team.providerId === teamId ||
                match.home.team.providerId === teamId
        );

        const roundByRoundVotes = rounds.map((round) => {
            const roundTeamMatches = teamMatches.filter(
                (match) => match.round.roundNumber === round.roundNumber
            );

            return {
                roundNumber: round.roundNumber,
                matches: roundTeamMatches
                    .map((match) => {
                        const leaderboardMatch = player.roundByRoundVotes.find(
                            (playerMatch) =>
                                playerMatch.matchId === match.providerId
                        );
                        const opponent =
                            match.away.team.providerId === teamId
                                ? match.home.team
                                : match.away.team;

                        return {
                            matchId: match.providerId,
                            opponent: opponent,
                            utcStartTime: match.utcStartTime,
                            votes: leaderboardMatch?.votes ?? 0
                        };
                    })
                    .sort(
                        (matchA, matchB) =>
                            new Date(matchA.utcStartTime).getTime() -
                            new Date(matchB.utcStartTime).getTime()
                    )
            };
        });
        const enrichedPlayer = {
            ...player,
            roundByRoundVotes: roundByRoundVotes
        };

        formattedLeaderboard.push(enrichedPlayer);
    });

    return formattedLeaderboard;
};
