import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StatsSection } from 'widgets/statspro/js/components/common/stats-section';
import { DraftTrackerLeaderboardTable } from './table/DraftTrackerLeaderboardTable';
import {
    ClubNavItem,
    PositionsNavItem,
    RoundNavItem,
    Search
} from './nav-items';
import { Legend } from './table/components/Legend';
import { useWindowSize } from 'common/react/hooks/use-window-resize';
import { SettingsButton } from 'common/react/components/SettingsButton';
import { useGetDraftByYearQuery } from './api/draft-tracker-api';

export const DraftTrackerLeaderboard = ({ seasonPid, seasonId }) => {
    document.body.classList.add('remove-sticky-nav');
    const year = seasonPid.substring(4, 8);

    const windowSize = useWindowSize();

    const isResponsive = () =>
        windowSize.width <= PULSE.app.measurements.desktop;
    const [showResponsiveItems, setShowResponsiveItems] = useState(
        isResponsive()
    );

    useEffect(() => {
        setShowResponsiveItems(isResponsive());
    }, [windowSize.width]);

    const { data: draftData } = useGetDraftByYearQuery(year);

    return (
        <>
            <StatsSection.Main>
                <StatsSection.Nav>
                    <StatsSection.NavItems>
                        {draftData && draftData.selections?.length && (
                            <RoundNavItem year={year} />
                        )}
                        {draftData && draftData.selections?.length && (
                            <ClubNavItem seasonId={seasonId} year={year} />
                        )}
                        {!showResponsiveItems ||
                        !(draftData && draftData.selections?.length) ? (
                            <PositionsNavItem
                                classes={'u-margin-right-auto'}
                                className={
                                    draftData && draftData.selections?.length
                                        ? 'select--rounded'
                                        : ''
                                }
                            />
                        ) : null}
                        {!showResponsiveItems ? (
                            <Search classes="search-filter-item" />
                        ) : null}
                        {showResponsiveItems ? (
                            <SettingsButton classes="settings-button-mobile" />
                        ) : null}
                    </StatsSection.NavItems>

                    {showResponsiveItems &&
                    draftData &&
                    draftData.selections?.length ? (
                        <StatsSection.NavFilterItems>
                            <PositionsNavItem year={year} />
                        </StatsSection.NavFilterItems>
                    ) : null}

                    {!showResponsiveItems ? (
                        <StatsSection.SettingsButton>
                            <SettingsButton />
                        </StatsSection.SettingsButton>
                    ) : null}

                    {showResponsiveItems ? (
                        <StatsSection.SearchBarMobile>
                            <Search />
                        </StatsSection.SearchBarMobile>
                    ) : null}
                </StatsSection.Nav>
                <StatsSection.Content>
                    <StatsSection.ContentBody>
                        <DraftTrackerLeaderboardTable year={year} />
                    </StatsSection.ContentBody>
                </StatsSection.Content>
            </StatsSection.Main>
            <Legend />
        </>
    );
};

DraftTrackerLeaderboard.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired
};
