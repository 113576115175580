import { put } from 'redux-saga/effects';
import { UPDATE_PLAYER_MODAL, setPlayerModal } from '../actions';

/**
 *
 * @param action
 */
export function* updatePlayerModal(action) {
    const {
        visible,
        playerId,
        seasonPid,
        seasonId,
        leaderBoardData,
        player,
        shouldHidePredicted
    } = action.payload;

    yield put(
        setPlayerModal({
            visible: visible,
            playerId: playerId ?? null,
            seasonPid: seasonPid ?? null,
            seasonId: seasonId ?? null,
            leaderBoardData: leaderBoardData ?? null,
            player: player ?? null,
            shouldHidePredicted: shouldHidePredicted ?? false
        })
    );
    yield put(UPDATE_PLAYER_MODAL.success());
}
