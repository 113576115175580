import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from './components/Table';
import { useSelector } from 'react-redux';
import {
    PlayerModalPredictedSelector,
    playerModalSelector
} from 'common/store/modules/brownlow-tracker/player-modal/selectors';
import { composeColumnData } from './utils';
import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';
import { Loader } from 'common/react/components/Loader';
import FocusableTooltipArea from 'common/react/components/tooltip-button/focusable-tooltip/FocusableTootlipArea';
import { leaderboardDataSelector } from 'common/store/modules/brownlow-tracker/leaderboard/selectors';

/**
 * Table of votes round by round
 * Shows Round Number, Opponent team, predicted votes and actual votes for each round.
 *
 * @param {object} props
 * @param {object} props.data Player data from leaderboard api
 * @param {Array} props.rounds List of all rounds in the season
 *
 * @param props.isWeekly
 * @returns
 */
export const RoundVotesTable = ({ data, rounds, isWeekly }) => {
    const playerModalData = useSelector(playerModalSelector);
    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, playerModalData.seasonPid)
    );
    const predictedData = useSelector(PlayerModalPredictedSelector);

    /**
     * looking for predicted votes for the player
     */
    const playerPredictedData = useMemo(() => {
        return predictedData?.players?.find(
            (item) => item.providerId === playerModalData.playerId
        );
    }, [predictedData, playerModalData]);

    /**
     * looking from the max roundNumber that is available in the leaderboard data
     * This is needed to limit the number of rows in the table to show only the rounds the votes wher submitted for
     */
    const maxVotesRound = useMemo(() => {
        if (leaderboardData?.leaderboard?.length) {
            return Math.max(
                ...leaderboardData.leaderboard.map((leaderboardPlayer) => {
                    return Math.max(
                        ...leaderboardPlayer.roundByRoundVotes.map(
                            (votes) => votes.roundNumber
                        )
                    );
                })
            );
        }

        return null;
    }, [leaderboardData]);

    /**
     * once predicted votes for the player are found
     * fetch matches for the player's team in the season
     */
    const {
        data: matches,
        isLoading,
        isFetching,
        isUninitialized
    } = useGetMatchesQuery(
        {
            pageSize: 25,
            compSeasonId: predictedData?.compSeason?.id,
            teamId: playerPredictedData?.teamId
        },
        {
            skip: !predictedData || !playerPredictedData
        }
    );

    // colums definition
    const columns = composeColumnData(isWeekly);

    /**
     * rows definition
     * combines all season rounds, with actual votes, and the finaly predicted votes
     * excludes rounds that are not Byes and have no opponent - as there will be nothing usefull to display for that round
     */
    const rows = useMemo(() => {
        if (maxVotesRound === null) {
            return [];
        }

        // filtering rounds that are not finals
        const combinedRounds =
            rounds
                ?.filter(
                    (round) =>
                        PULSE.app.common.match.isNonFinalRound(round) &&
                        round.roundNumber <= maxVotesRound
                )
                .flatMap((round) => {
                    // finding predicted votes for the round
                    const predictedRound =
                        playerPredictedData?.rounds?.[round.roundNumber];

                    // finding matches by round
                    const foundMatches = matches?.filter(
                        (matchData) =>
                            matchData.round.roundNumber === round.roundNumber
                    );

                    return foundMatches
                        ?.map((match) => {
                            let opponent;
                            opponent = {
                                matchPid: match.providerId,
                                matchId: match.id,
                                ...(match.home.team.id ===
                                playerPredictedData.teamId
                                    ? match.away.team
                                    : match.home.team)
                            };

                            // finding votes for each match
                            const votesRound = data?.roundByRoundVotes?.find(
                                (votesRoundData) =>
                                    votesRoundData.matchId === match.providerId
                            );

                            // mapping the match row to the predicted match providerId
                            const predictedMatch = predictedRound?.find(
                                (predMatch) =>
                                    predMatch.providerId === match.providerId
                            );

                            let bye = predictedMatch?.bye;

                            return {
                                roundNumber: round.roundNumber,
                                player: playerModalData,
                                opponent: opponent,
                                utcStartTime: match.utcStartTime,
                                predictedPoints:
                                    bye || predictedMatch?.played === false
                                        ? '-'
                                        : predictedMatch?.points ?? 0,
                                votes:
                                    predictedMatch?.played === false
                                        ? '-'
                                        : votesRound?.votes ?? 0,
                                played: predictedMatch?.played !== false,
                                bye: predictedMatch?.bye
                            };
                        })
                        .sort(
                            (matchA, matchB) =>
                                new Date(matchA.utcStartTime).getTime() -
                                new Date(matchB.utcStartTime).getTime()
                        );
                })
                .reverse() ?? [];

        // filtering out bye rounds and rounds where the team didn't play
        return combinedRounds.filter((round) => round?.opponent || round?.bye);
    }, [
        data,
        rounds,
        playerPredictedData,
        playerModalData,
        matches,
        maxVotesRound
    ]);

    if (isLoading || isFetching || isUninitialized) {
        return (
            <div className="career-and-season-stats__loader">
                <Loader />
            </div>
        );
    }

    return (
        <div className="brownlow-player-modal__round-votes-table">
            <FocusableTooltipArea>
                <Table
                    columns={columns}
                    data={rows}
                    seasonPid={playerModalData.seasonPid}
                    isWeekly
                />
            </FocusableTooltipArea>
        </div>
    );
};

RoundVotesTable.propTypes = {
    data: PropTypes.object,
    rounds: PropTypes.array,
    isWeekly: PropTypes.bool
};
