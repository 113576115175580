import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UPDATE_NAV_ITEM } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { leaderboardNavSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';

export const useLeaderboardNavSync = ({
    competitions,
    seasons,
    teams,
    defaultSeason,
    seasonsToShow,
    competitionId
}) => {
    const dispatch = useDispatch();
    const nav = useSelector(leaderboardNavSelector);

    const { filteredSeasons, setDefaultSeason } = useMemo(() => {
        const seasonsToShowArray = seasonsToShow
            ? seasonsToShow.replace(/ /g, '').split(',')
            : '';

        return {
            filteredSeasons: seasons
                ? seasons.filter((season) => {
                      return seasonsToShowArray.includes(season.providerId);
                  })
                : [],
            setDefaultSeason:
                seasons?.find(
                    (season) => season.providerId === defaultSeason
                ) || {}
        };
    }, [defaultSeason, seasons, seasonsToShow]);

    /**
     * Set initial nav values
     */
    useEffect(() => {
        if (competitions && !nav.competition.id) {
            if (competitionId) {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'competition',
                        value: { id: competitionId }
                    })
                );
            } else {
                const { PREMIERSHIP } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
                const aflPremiershipCompetition = competitions.find(
                    (comp) => comp.providerId === PREMIERSHIP
                );

                if (aflPremiershipCompetition) {
                    dispatch(
                        UPDATE_NAV_ITEM.request({
                            navItem: 'competition',
                            value: { id: aflPremiershipCompetition.id }
                        })
                    );
                }
            }
        }

        if (seasons && !nav.season.id) {
            if (Object.keys(setDefaultSeason).length !== 0) {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: { id: setDefaultSeason.id }
                    })
                );
            } else {
                dispatch(
                    UPDATE_NAV_ITEM.request({
                        navItem: 'season',
                        value: {
                            id: filteredSeasons.length
                                ? filteredSeasons[0].id
                                : seasons[0].id
                        }
                    })
                );
            }
        }

        if (teams && !nav.team.id) {
            dispatch(
                UPDATE_NAV_ITEM.request({
                    navItem: 'team',
                    value: { id: teams[0].id }
                })
            );
        }
    }, [
        competitions,
        dispatch,
        filteredSeasons,
        nav.competition.id,
        nav.season.id,
        nav.team.id,
        seasons,
        setDefaultSeason,
        teams,
        competitionId
    ]);
};
