import React, { useEffect } from 'react';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { getTranslation } from 'common/react/utils/translations';
import { useDispatch, useSelector } from 'react-redux';
import { playerModalSelector } from 'store/modules/brownlow-tracker/player-modal/selectors';
import { FETCH_PLAYER_MODAL_LEADERBOARD_DATA } from 'store/modules/brownlow-tracker/player-modal/actions';
import { RoundVotesTable } from './round-votes';
import { SeasonContext } from '../../Leaderboard/components/table/LeaderboardTableFetcher';

/**
 * Renders bottom section of the Brownlow Player Modal
 * with round by round votes table
 *
 * @returns {HTMLElement}
 */
export const PlayerModalRoundByRound = () => {
    const dispatch = useDispatch();
    const playerData = useSelector(playerModalSelector);
    const rounds = useSelector(
        (state) => state.rounds[`season_${playerData.seasonId}`]?.list
    );

    const isWeekly =
        rounds?.length && rounds[0].name.toLowerCase().includes('week');

    useEffect(() => {
        // Update current player
        if (
            !playerData.leaderBoardData ||
            playerData.leaderBoardData.seasonId !== playerData.seasonPid
        ) {
            dispatch(
                FETCH_PLAYER_MODAL_LEADERBOARD_DATA.request({
                    seasonPid: playerData.seasonPid
                })
            );
        }

        // Fetch rounds list
        if (!rounds) {
            dispatch(
                window.PULSE.app.redux.actions.rounds.API__FETCH_ROUNDS.request(
                    playerData.seasonId
                )
            );
        }
    }, [dispatch, playerData, rounds]);

    return (
        <div className="brownlow-player-modal__round-by-round">
            <div className="brownlow-player-modal__round-by-round-header">
                <span className="brownlow-player-modal__label brownlow-player-modal__label--full-width-mobile">
                    {isWeekly
                        ? getTranslation(
                              'label.playerAwards.tracker.weekByWeek'
                          )
                        : getTranslation('label.brownlow.tracker.roundByRound')}
                </span>

                <TooltipButton
                    id="brownlow-player-modal-tooltip"
                    title="Select a round to view details"
                    classNames={{
                        component: 'brownlow-player-modal-tooltip',
                        button: 'brownlow-player-modal-tooltip__button'
                    }}
                    direction="above"
                    primaryTooltipContent={
                        <div className="brownlow-player-modal-tooltip__content">
                            {isWeekly
                                ? getTranslation(
                                      'label.playerAwards.tracker.weekTooltipContent'
                                  )
                                : getTranslation(
                                      'label.brownlow.tracker.tooltipContent'
                                  )}
                        </div>
                    }
                >
                    <SvgIcon
                        icon="info"
                        className="brownlow-player-modal-tooltip__icon"
                    />
                    {isWeekly
                        ? getTranslation(
                              'label.brownlow.tracker.weekTooltipTargetText'
                          )
                        : getTranslation(
                              'label.brownlow.tracker.tooltipTargetText'
                          )}
                </TooltipButton>
            </div>
            <div className="brownlow-player-modal__round-by-round-content">
                <SeasonContext.Provider value={{ id: playerData.seasonId }}>
                    <RoundVotesTable
                        data={
                            playerData?.leaderBoardData?.leaderboard?.find(
                                (item) =>
                                    item.player.playerId === playerData.playerId
                            ) ?? {}
                        }
                        rounds={rounds ?? []}
                        tooltips={true}
                        isWeekly={isWeekly}
                    />
                </SeasonContext.Provider>
            </div>
        </div>
    );
};
