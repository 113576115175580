/**
 * @constant {string} The service name we use for this application with the
 * salesforce apis
 */
export const SERVICE_NAME = 'AFL Digital';

/**
 * @constant {object} The default properties and their values for the consents object in the salesforce apis
 */
export const DEFAULT_CONSENTS = {
    marketing: false
};

/**
 * Takes a users consents object and makes sure it has all required properties
 *
 * @param {object} userConsents - The users consents object
 * @returns {object} The user consents object that contains all that's required
 */
export const modelConsents = (userConsents = {}) => {
    // Check we have a setting for every required consent, if not add default
    for (const key in DEFAULT_CONSENTS) {
        if (Object.prototype.hasOwnProperty.call(DEFAULT_CONSENTS, key)) {
            const setting = DEFAULT_CONSENTS[key];
            if (typeof setting === 'undefined') {
                userConsents[key] = setting;
            }
        }
    }

    return userConsents;
};

/**
 * Creates the request body for a "Retrieve" preferences salesforce API call
 *
 * @returns {object} formatted request body with all details required by the api
 */
export const getRetrieveBody = () => ({
    user: {
        type: 'AFLid-guid',
        id: PULSE.app.authHelpers.getUserUid()
    },
    services: [
        {
            name: SERVICE_NAME
        }
    ]
});

/**
 * Creates the request body for a "Create" or an "Update" preferences salesforce
 * API call given some incoming consents. If not
 * given any, it will fill them in with modelled defaults.
 *
 * @param {object} params - An object with properties for the incoming preferences
 * @param {object} params.consents - The consents object, gets run through
 * modelConsents incase you don't pass all required properties
 * @returns {object} formatted request body with all details required by the api
 */
export const getCreateBody = ({ consents = DEFAULT_CONSENTS }) => ({
    user: {
        type: 'AFLid-guid',
        id: PULSE.app.authHelpers.getUserUid()
    },
    services: [
        {
            name: SERVICE_NAME,
            consents: modelConsents(consents)
        }
    ]
});

/**
 * Creates the request body for a "Create" or an "Update" preferences salesforce
 * API call given some incoming preferences and subscriptions. If not
 * given any, it will fill them in with modelled defaults.
 *
 * @param {object} params - An object with properties for the incoming preferences
 * @param {Array} params.preferences - Preferences array, not used
 * @param {Array} params.subscriptions - The subscriptions array with objects
 * each with the correct name, status properties
 * @returns {object} formatted request body with all details required by the api
 */
export const getUpdateBody = ({ preferences = [], subscriptions = [] }) => {
    return {
        user: {
            type: 'AFLid-guid',
            id: PULSE.app.authHelpers.getUserUid()
        },
        services: [
            {
                name: SERVICE_NAME,
                preferences,
                subscriptions
            }
        ]
    };
};
