import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useGetBrownlowBettingOddsQuery } from 'store/modules/api/cfs-api';

import { useBettingVis } from 'common/react/hooks/use-betting-vis';
import { SeasonContext } from '../../LeaderboardTableFetcher';
import { useSelector } from 'react-redux';
import { leaderboardDataSelector } from 'common/store/modules/brownlow-tracker/leaderboard/selectors';

export const MobileTable = ({ children, nextPageButton, isBrownlowV2 }) => {
    const { data: bettingOddsData } = useGetBrownlowBettingOddsQuery(null, {
        pollingInterval: PULSE.app.common.CONSTANTS.TIME.ONE_MINUTE_IN_MS
    });

    const season = useContext(SeasonContext);

    const leaderboardData = useSelector((state) =>
        leaderboardDataSelector(state, season?.providerId)
    );

    const status = leaderboardData?.status
        ? PULSE.app.common.match.getMatchStatus(leaderboardData.status)
        : '';

    const shouldShowBettingOdds =
        useBettingVis() &&
        status !== PULSE.app.common.CONSTANTS.MATCH_STATUS.COMPLETED &&
        bettingOddsData?.displayInApp === true &&
        bettingOddsData?.outcomes?.length > 0;

    const modifier = shouldShowBettingOdds
        ? 'brownlow-tracker-leaderboard-mobile-table--show-odds'
        : '';

    return (
        <>
            <div
                className={`brownlow-tracker-leaderboard-mobile-table ${
                    isBrownlowV2 ? 'brownlow-predictor-mobile' : ''
                } ${modifier}`}
            >
                {children}
            </div>
            {nextPageButton}
        </>
    );
};

MobileTable.propTypes = {
    seasonPid: PropTypes.string,
    children: PropTypes.any.isRequired,
    isBrownlowV2: PropTypes.bool,
    nextPageButton: PropTypes.any,
    modifier: PropTypes.string
};
