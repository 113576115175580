import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { PlayerModal } from '../components/player-modal';

export const PlayerAwardsPlayerModalProvider = ({ store }) => (
    <Provider store={store}>
        <PlayerModal />
    </Provider>
);

PlayerAwardsPlayerModalProvider.propTypes = {
    store: PropTypes.object.isRequired
};
