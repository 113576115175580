import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { UPDATE_PLAYER_MODAL } from 'store/modules/brownlow-tracker/player-modal/actions';
import { getTranslation } from 'common/react/utils/translations';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';

const VOTES_COUNT = {
    THREE: 3,
    TWO: 2,
    ONE: 1
};

export const MatchCardTable = ({
    voteData,
    seasonPid,
    seasonId,
    modifier,
    playerModalEnabled,
    leaderboardData,
    shouldHidePredicted
}) => {
    const dispatch = useDispatch();
    const numberOfPlayers = 3;
    const composedPlayers = useMemo(
        () => buildOutPlayersArray(voteData, leaderboardData),
        [voteData, leaderboardData]
    );

    // Activate the player modal
    const activatePlayerModal = (event, player) => {
        // prevent wrapping <a> element from receiving a click
        if (
            (event.keyCode &&
                event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.ENTER &&
                event.keyCode !== PULSE.app.common.CONSTANTS.KEY_CODES.SPACE) ||
            !playerModalEnabled
        ) {
            return;
        }

        dispatch(
            UPDATE_PLAYER_MODAL.request({
                visible: true,
                playerId: player.playerId,
                seasonPid: seasonPid,
                seasonId: seasonId ?? null,
                player,
                shouldHidePredicted: shouldHidePredicted
            })
        );
    };

    return (
        <table className="brownlow-match-card__table">
            <tbody>
                {composedPlayers.map((item, index) => (
                    <tr
                        key={index}
                        className={`brownlow-match-card__table-row`}
                    >
                        <td
                            className={`brownlow-match-card__table-column-number ${
                                item.player
                                    ? ''
                                    : 'brownlow-match-card__table-column-number--empty'
                            }`}
                        >
                            {numberOfPlayers - index}
                        </td>
                        <td className="brownlow-match-card__table-column-name">
                            <div
                                className={`brownlow-match-card__name-inner-container ${
                                    item.player && !item.eligible
                                        ? 'brownlow-match-card__ineligible'
                                        : ''
                                }`}
                            >
                                {modifier !== 'round-by-round' ? (
                                    <>
                                        <SvgIcon
                                            icon={`aflc-${item.player?.team.teamAbbr.toLowerCase()}`}
                                            className="icon brownlow-match-card__player-team-icon"
                                        />
                                        <span className="brownlow-match-card__given-name">
                                            {item.player && (
                                                <>
                                                    {item.player?.player?.givenName?.substring(
                                                        0,
                                                        1
                                                    )}
                                                    .
                                                </>
                                            )}
                                        </span>
                                        <span className="brownlow-match-card__last-name">
                                            {item.player?.player.surname}
                                        </span>
                                        {item.player && !item.eligible && (
                                            <span className="brownlow-match-card__ineligible-tag brownlow-match-card__ineligible-tag-short">
                                                I
                                            </span>
                                        )}
                                    </>
                                ) : item.player ? (
                                    <>
                                        <PlayerPhoto
                                            className={
                                                'brownlow-match-card__photo'
                                            }
                                            photoUrl={
                                                item.player?.player.photoURL
                                            }
                                            scale={'0.1'}
                                            alt={`${getTranslation(
                                                'label.stats.headshot.alt'
                                            )} ${
                                                item.player?.player.givenName ??
                                                ''
                                            } ${
                                                item.player?.player.surname ??
                                                ''
                                            }`}
                                        />
                                        <button
                                            className="brownlow-match-card__name-button"
                                            onClick={(event) =>
                                                item.player &&
                                                activatePlayerModal(
                                                    event,
                                                    item.player?.player
                                                )
                                            }
                                            onKeyDown={(event) =>
                                                item.player &&
                                                activatePlayerModal(
                                                    event,
                                                    item.player?.player
                                                )
                                            }
                                            type="button"
                                        >
                                            <div className="brownlow-match-card__name-inner-container-text">
                                                <>
                                                    {
                                                        item.player?.player
                                                            .givenName
                                                    }{' '}
                                                    {
                                                        item.player?.player
                                                            .surname
                                                    }
                                                </>
                                                {!item.eligible && (
                                                    <span className="brownlow-match-card__ineligible-tag">
                                                        {getTranslation(
                                                            'label.brownlow.predictor.ineligible'
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        </button>
                                    </>
                                ) : null}
                            </div>
                        </td>
                        <td
                            className={`brownlow-match-card__table-column-total ${
                                !item.eligible
                                    ? 'brownlow-match-card__ineligible'
                                    : ''
                            }`}
                        >
                            {item.votes ?? '-'}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

MatchCardTable.propTypes = {
    voteData: PropTypes.object,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    modifier: PropTypes.string,
    playerModalEnabled: PropTypes.bool,
    leaderboardData: PropTypes.object,
    shouldHidePredicted: PropTypes.bool
};

/**
 * Take a players votes in a match and decide where in the array they should be
 * positioned
 *
 * @param {number} voteCount - How many votes a player recieved
 * @returns {number} The index to place them at in the array
 */
const getArrayPosition = (voteCount) => {
    switch (voteCount) {
        case VOTES_COUNT.THREE:
            return 0;
        case VOTES_COUNT.TWO:
            return 1;
        case VOTES_COUNT.ONE:
            return 2;
        default:
            return null;
    }
};

/**
 *
 * @param {number} roundNumber - The round selected.
 * @param {string} playerId - The player who's votes we'll calculate.
 * @param {Array} leaderboardData - The players array that contains their round by round total votes information.
 * @returns {number} - The total of votes up until the provided roundNumber.
 */
const getRoundByRoundVotes = (roundNumber, playerId, leaderboardData) => {
    let totalVotesToRound = 0;

    if (leaderboardData) {
        leaderboardData.forEach((player) => {
            if (player.player.playerId === playerId) {
                player.roundByRoundVotes.forEach((round) => {
                    if (round.roundNumber <= roundNumber) {
                        totalVotesToRound = totalVotesToRound + round.votes;
                    }
                });
            }
        });
    }

    return totalVotesToRound;
};

/**
 * Take the vote data for a match and the players leaderboard array
 * and build out an array with 3 objects in, one for each row. Work
 * through each vote for a match and assign it to the correct position in the
 * array.
 *
 * @param {object} voteData - The voting data for a match
 * @param {Array} leaderboardData - The leaderboard data object that contains their round by round total votes array.
 * @returns {Array} The players array to be used in the component
 */
const buildOutPlayersArray = (voteData, leaderboardData) => {
    // Array of 3 objects ready to fill with players and their votes
    const initialState = [{}, {}, {}];

    const votesArray = voteData?.votes ?? [];

    for (const vote of votesArray) {
        initialState[getArrayPosition(vote.votes)] = {
            player: vote,
            votes: getRoundByRoundVotes(
                voteData.roundNumber,
                vote.player.playerId,
                leaderboardData?.leaderboard
            ),
            eligible: vote?.eligible
        };
    }

    return initialState;
};
