import React from 'react';
import PropTypes from 'prop-types';

import { MatchCardElement } from './match-card-element';
import { MatchCardHeader } from './match-card-header';
import { MatchCardTable } from './match-card-table';
import { Loader } from 'common/react/components/Loader';
import { useMatchModel } from './hooks/use-match-model';

export const MatchCard = ({
    voteData,
    matchData,
    seasonPid,
    seasonId,
    modifier,
    linkTo,
    playerModalEnabled,
    leaderboardData
}) => {
    const match = useMatchModel(matchData);

    return (
        <div
            className={`brownlow-match-card ${
                modifier ? 'brownlow-match-card--' + modifier : ''
            }`}
        >
            <div className="stats-slider__card">
                {!match ? (
                    <Loader />
                ) : (
                    <MatchCardElement
                        linkTo={linkTo}
                        playerModalEnabled={playerModalEnabled}
                    >
                        <MatchCardHeader
                            voteData={voteData}
                            matchData={match}
                            modifier={modifier}
                        />
                        <MatchCardTable
                            voteData={voteData}
                            seasonPid={seasonPid}
                            seasonId={seasonId}
                            modifier={modifier}
                            playerModalEnabled={playerModalEnabled}
                            leaderboardData={leaderboardData}
                        />
                    </MatchCardElement>
                )}
            </div>
        </div>
    );
};

MatchCard.propTypes = {
    voteData: PropTypes.object,
    matchData: PropTypes.object,
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    modifier: PropTypes.string,
    linkTo: PropTypes.string,
    playerModalEnabled: PropTypes.bool,
    leaderboardData: PropTypes.object
};
