import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SvgIcon } from 'common/react/components/SvgIcon';
import {
    BREAKPOINTS_FOR_EMBEDDABLE,
    BREAKPOINTS_FOR_SIX_SLIDES,
    sliderSettings
} from '../config/slider';
import { MatchCardScoreFetcher } from './match-card-score-fetcher';
import { FETCH_MATCH_BY_MATCH_DATA } from 'store/modules/brownlow-tracker/match-by-match/actions';
import { matchByMatchSelector } from 'store/modules/brownlow-tracker/match-by-match/selectors';
import { useSwiperTracking } from 'common/react/hooks/use-swiper-tracking';

export const LiveTrackerSlider = ({
    seasonPid,
    seasonId,
    embeddable,
    linkTo,
    playerModalEnabled,
    anchorName
}) => {
    const MAX_SLIDES = 15;
    const MIN_SLIDES = 3;
    const sliderName =
        'live-tracker-' + (!embeddable ? '' : 'embeddable-') + anchorName;
    const dispatch = useDispatch();
    const matchesData = useSelector((state) =>
        matchByMatchSelector(state, seasonPid)
    );
    const latestMatchesData = useMemo(() => {
        return matchesData?.matchVotes.slice(0, MAX_SLIDES).reverse();
    }, [matchesData?.matchVotes]);

    const { handleTransitionEnd } = useSwiperTracking(sliderName);

    useEffect(() => {
        dispatch(
            FETCH_MATCH_BY_MATCH_DATA.request({
                seasonPid: seasonPid
            })
        );
    }, [dispatch, seasonPid]);

    return (
        <div className="brownlow-live-tracker">
            <div className="wrapper" style={{ marginBottom: 0 }}>
                <div
                    className={`brownlow-live-tracker__slider stats-slider ${
                        latestMatchesData?.length > MIN_SLIDES
                            ? 'has-controls'
                            : ''
                    }`}
                >
                    <Swiper
                        {...sliderSettings(sliderName)}
                        {...(embeddable
                            ? BREAKPOINTS_FOR_EMBEDDABLE
                            : BREAKPOINTS_FOR_SIX_SLIDES)}
                        onTransitionEnd={handleTransitionEnd}
                    >
                        {latestMatchesData &&
                            latestMatchesData.map((matchVotes, index) => (
                                <SwiperSlide key={index}>
                                    {({ isVisible }) => (
                                        <MatchCardScoreFetcher
                                            voteData={matchVotes}
                                            totalVotes={matchesData.totalVotes}
                                            seasonPid={seasonPid}
                                            seasonId={seasonId}
                                            isVisible={isVisible}
                                            modifier={
                                                embeddable
                                                    ? 'embeddable'
                                                    : 'full-width'
                                            }
                                            linkTo={linkTo}
                                            playerModalEnabled={
                                                playerModalEnabled
                                            }
                                        />
                                    )}
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    <div
                        className={`swiper-button-prev swiper-button-prev-unique-${sliderName}`}
                    >
                        <SvgIcon icon="chevron" className="icon" />
                    </div>
                    <div
                        className={`swiper-button-next swiper-button-next-unique-${sliderName}`}
                    >
                        <SvgIcon icon="chevron" className="icon" />
                    </div>
                </div>
            </div>
        </div>
    );
};

LiveTrackerSlider.propTypes = {
    seasonPid: PropTypes.string.isRequired,
    seasonId: PropTypes.string.isRequired,
    embeddable: PropTypes.string,
    linkTo: PropTypes.string,
    playerModalEnabled: PropTypes.bool,
    anchorName: PropTypes.string
};
