/* eslint-disable camelcase */
import { useState } from 'react';

const EVENTS = PULSE.app.common.CONSTANTS.TRACKING.EVENTS;

export const useSwiperTracking = (sliderName, componentContentType) => {
    const [touchesDiff, setTouchesDiff] = useState(0);

    const handleTransitionEnd = (swiperCore, customType) => {
        const type =
            swiperCore.touches.diff && swiperCore.touches.diff !== touchesDiff
                ? EVENTS.CAROUSEL.TYPE.SWIPE
                : EVENTS.CAROUSEL.TYPE.ARROW;

        const direction =
            swiperCore.activeIndex > swiperCore.previousIndex
                ? EVENTS.CAROUSEL.DIRECTION.NEXT
                : EVENTS.CAROUSEL.DIRECTION.PREVIOUS;

        PULSE.app.tracking.recordEvent(EVENTS.CAROUSEL.EVENT_NAME, {
            eventData: {
                component_name: sliderName || '',
                component_content_type: componentContentType || '',
                count: swiperCore.snapGrid.length,
                index: swiperCore.snapIndex + 1,
                type: customType || type,
                direction: direction
            }
        });

        setTouchesDiff(swiperCore.touches.diff);
    };

    return {
        handleTransitionEnd
    };
};
