import { createApi } from '@reduxjs/toolkit/query/react';
import { bearerTokenSalesForceQuery } from './base-queries/bearer-token-salesforce-query';
import { getRetrieveBody, SERVICE_NAME } from './util/salesforce-helpers';

export const salesforceSitApi = createApi({
    reducerPath: 'salesforceSitApi',
    baseQuery: bearerTokenSalesForceQuery,
    endpoints: (builder) => ({
        retrieveMarketingPrefs: builder.query({
            query: () => ({
                url: `/v1/member/query/marketing-preferences`,
                method: 'POST',
                body: getRetrieveBody()
            }),
            transformResponse: (response) => {
                const { preferences, subscriptions, consents } =
                    response.services.find(
                        (service) => service.name === SERVICE_NAME
                    ) || {};
                return {
                    subscriptions,
                    preferences,
                    consents
                };
            }
        }),
        createMarketingPrefs: builder.mutation({
            query: (preferences) => ({
                url: '/v1/member/marketing-preferences',
                method: 'POST',
                body: preferences
            })
        }),
        updateMarketingPrefs: builder.mutation({
            query: (preferences) => ({
                url: `/v1/member/marketing-preferences`,
                method: 'PUT',
                body: preferences
            })
        })
    })
});

export const {
    useDescribeMarketingPrefsQuery,
    useRetrieveMarketingPrefsQuery,
    useCreateMarketingPrefsMutation,
    useUpdateMarketingPrefsMutation
} = salesforceSitApi;
