import { put, call } from 'redux-saga/effects';
import { fetchWithMisToken } from '../../../../utils/fetch-with-mis-token';
import {
    FETCH_PLAYER_MODAL_LEADERBOARD_DATA,
    removeModalLeaderboardDataRequest,
    setModalLeaderboardData,
    setModalLeaderboardDataRequest
} from '../actions';

/**
 * @typedef {object} FetchLeaderboardDataAction
 * @property {object} payload
 * @property {string} [payload.seasonPid]
 */

/**
 * @param {FetchLeaderboardDataAction} action - The Redux action.
 */
export function* fetchModalLeaderboardData(action) {
    const { seasonPid, teamId } = action.payload;

    yield put(setModalLeaderboardDataRequest(action.payload));

    let data = {};

    if (seasonPid) {
        data = yield call(
            fetchWithMisToken,
            `${
                PULSE.app.environment.cfsApi
            }/bfawards/leaderboard/season/${seasonPid}${
                teamId ? `?teamId=${teamId}` : ''
            }`
        );

        if (data) {
            yield put(setModalLeaderboardData({ data: data }));
        }
    }

    yield put(removeModalLeaderboardDataRequest(action.payload));

    yield put(FETCH_PLAYER_MODAL_LEADERBOARD_DATA.success(data));
}
