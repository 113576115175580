import React from 'react';
import PropTypes from 'prop-types';

import { ModalTitle } from './ModalTitle';
import { ModalAflIdPromo } from './ModalAflIdPromo';
import { ModalToggle } from './ModalToggle';
import { ModalLink } from './ModalLink';

import { getTranslation } from 'common/react/utils/translations';

export const LoggedOutModal = ({
    siteSettings,
    setSiteSettings,
    settingsConfig,
    selectedRegion,
    displayWagering,
    regionsPage,
    isOVer18Setting
}) => {
    const pageHasAflIdLoginButton = document.querySelector('.js-aflid-user');

    return (
        <>
            <ModalTitle
                icon="settings"
                title={getTranslation('label.settings')}
            />
            {pageHasAflIdLoginButton ? (
                <ModalAflIdPromo
                    text={getTranslation('settingsModal.aflId.text')}
                    buttons={[
                        {
                            label: getTranslation('label.aflid.create'),
                            onClick: () => {
                                /* eslint-disable camelcase */
                                PULSE.app.tracking.recordEvent(
                                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                        .SELECT.CTA,
                                    {
                                        eventData: {
                                            link_text:
                                                getTranslation(
                                                    'label.aflid.create'
                                                ),
                                            link_url: `${window.location.origin}${PULSE.app.environment.okta.redirectPath}`,
                                            outbound: true,
                                            type: 'external',
                                            component_name: 'Logged out modal'
                                        }
                                    }
                                );
                                /* eslint-enable camelcase */

                                PULSE.app.authHelpers.signInWithRedirect();
                            }
                        },
                        {
                            label: getTranslation('label.aflid.login'),
                            onClick: () => {
                                /* eslint-disable camelcase */
                                PULSE.app.tracking.recordEvent(
                                    PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                        .SELECT.CTA,
                                    {
                                        eventData: {
                                            link_text:
                                                getTranslation(
                                                    'label.aflid.login'
                                                ),
                                            link_url: `${window.location.origin}${PULSE.app.environment.okta.redirectPath}`,
                                            outbound: true,
                                            type: 'external',
                                            component_name: 'Logged out modal'
                                        }
                                    }
                                );
                                /* eslint-enable camelcase */

                                PULSE.app.authHelpers.signInWithRedirect();
                            }
                        }
                    ]}
                />
            ) : null}
            <ModalToggle
                icon="id-card"
                label={getTranslation('label.18OrOver')}
                isChecked={siteSettings[settingsConfig.over18.key]}
                onChange={(checked) => {
                    setSiteSettings({
                        [settingsConfig.over18.key]: checked
                    });
                    if (!checked) {
                        setSiteSettings({
                            [settingsConfig.displayWagering.key]: false
                        });
                    }
                }}
                cssClass="first-of-stack"
            />
            <ModalToggle
                icon="sportsbet"
                label={getTranslation('label.hideBettingOdds')}
                isChecked={displayWagering}
                onChange={(checked) =>
                    setSiteSettings({
                        [settingsConfig.displayWagering.key]: !checked
                    })
                }
                isDisabled={!isOVer18Setting}
                notes={getTranslation('settingsModal.bettingOdds.note')}
                cssClass="last-of-stack"
            />
            <ModalLink
                icon="globe"
                label={getTranslation('label.filters.region')}
                onClick={(event) => {
                    /**
                     * Close the modal before navigating, so it isn't open on
                     * the select region page
                     */
                    event.preventDefault();
                    PULSE.app.common.siteSettings.closeSettingsModal();
                    window.location.href = event.currentTarget.href;
                }}
                link={`${window.location.origin}${regionsPage}`}
                btnLabel={selectedRegion.name}
            />
        </>
    );
};

LoggedOutModal.propTypes = {
    siteSettings: PropTypes.object.isRequired,
    setSiteSettings: PropTypes.func.isRequired,
    settingsConfig: PropTypes.object.isRequired,
    selectedRegion: PropTypes.object.isRequired,
    displayWagering: PropTypes.bool.isRequired,
    regionsPage: PropTypes.string.isRequired,
    isOVer18Setting: PropTypes.bool.isRequired
};
