import React from 'react';
import { TableHead } from './TableHead';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import { TableBody } from './TableBody';
import { getTranslation } from 'common/react/utils/translations';
import { TooltipContent } from 'widgets/player-awards-tracker/js/components/Tooltip';

/**
 * Round by round votes table
 *
 * @param {object} props
 * @param {Array} props.columns
 * @param {Array} props.data
 * @returns
 */
export const Table = ({ columns, data }) => {
    const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            disableSortRemove: true,
            disableSortBy: true
        },
        useSortBy
    );

    return (
        <div className="brownlow-player-modal-table">
            <table className="stats-table__table">
                <TableHead headerGroup={headerGroups[0]} />
                {data?.length === 0 ? (
                    <tbody>
                        <tr>
                            <td
                                colSpan={columns.length}
                                className="stats-table__cell stats-table__cell--no-player-votes"
                            >
                                <p className="stats-section-error">
                                    {getTranslation(
                                        'label.brownlow.noPlayerVotes'
                                    )}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <TableBody
                        rows={rows}
                        prepareRow={prepareRow}
                        getTableBodyProps={getTableBodyProps}
                        primaryTooltipComponent={(row, cell) => (
                            <TooltipContent
                                row={row}
                                cell={cell}
                                player={row.player}
                                seasonPid={row.seasonPid}
                            />
                        )}
                    />
                )}
            </table>
        </div>
    );
};

Table.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array
};
