import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FeaturedHeadToHeadCard } from './components/FeaturedHeadToHeadCard';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch } from 'react-redux';
import {
    BREAKPOINTS_FOR_THREE_SLIDES,
    sliderSettings
} from '../../config/slider';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { BenchmarkingExplainer } from 'common/react/components/benchmarking';
import { STAT_CATEGORIES } from './config/categories';
import { compareAllListener } from './utils/compare-all-listener';
import { REMOVE_PLAYER } from 'store/modules/statspro/player-comparison/actions';
import { EmptyState } from 'common/react/components/EmptyState';

import { useGetPlayerHeadToHeadQuery } from 'store/modules/api/statsPro-api';
import { useSwiperTracking } from 'common/react/hooks/use-swiper-tracking';

export const FeaturedHeadToHead = ({
    seasonId,
    competitionPid,
    showBenchmarkingAflw
}) => {
    const sliderName = 'featured-head-to-head';
    const dispatch = useDispatch();

    const {
        data: data,
        isLoading,
        isError
    } = useGetPlayerHeadToHeadQuery(seasonId, { skip: !seasonId });

    const showBenchmarkingAFLWBool = showBenchmarkingAflw === 'true';
    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLWPrem = competitionPid === WOMENS;
    const showBenchmarking =
        !isAFLWPrem || (isAFLWPrem && showBenchmarkingAFLWBool);

    useEffect(() => {
        // add a listener for the Compare All Players button on the Stats homepage
        const removePlayers = () => {
            let params = new URLSearchParams(location.search);
            dispatch(
                REMOVE_PLAYER.request({ id: params.get('playerOneId'), key: 1 })
            );
            dispatch(
                REMOVE_PLAYER.request({ id: params.get('playerTwoId'), key: 2 })
            );
        };
        compareAllListener(removePlayers);
    }, []);

    const { handleTransitionEnd } = useSwiperTracking(
        sliderName,
        PULSE.app.common.CONSTANTS.TRACKING.EVENTS.CAROUSEL.CONTENT_TYPE.PLAYER
    );

    return isError || !data ? (
        <>
            <EmptyState
                titleTranslation="label.statsPro.featureHeadToHead.noData.title"
                summaryTranslation="label.statsPro.featureHeadToHead.noData.summary"
            />
        </>
    ) : (
        <>
            {showBenchmarking && <BenchmarkingExplainer />}

            <div
                className={classNames(
                    'featured-head-to-head__slider stats-slider fade-in-on-load',
                    {
                        'is-loaded': !isLoading
                    }
                )}
            >
                <Swiper
                    {...sliderSettings(sliderName)}
                    {...BREAKPOINTS_FOR_THREE_SLIDES}
                    onSnapIndexChange={handleTransitionEnd}
                >
                    {Object.keys(STAT_CATEGORIES).map((key, i) =>
                        data[key] ? (
                            <SwiperSlide key={i}>
                                <FeaturedHeadToHeadCard
                                    players={data[key]}
                                    position={key}
                                    showBenchmarkingAflw={showBenchmarkingAflw}
                                    isAFLW={isAFLWPrem}
                                />
                            </SwiperSlide>
                        ) : null
                    )}
                </Swiper>
                <div
                    className={`swiper-button-prev swiper-button-prev-unique-${sliderName}`}
                >
                    <SvgIcon icon="chevron" className="icon" />
                </div>
                <div
                    className={`swiper-button-next swiper-button-next-unique-${sliderName}`}
                >
                    <SvgIcon icon="chevron" className="icon" />
                </div>
            </div>
        </>
    );
};

FeaturedHeadToHead.propTypes = {
    seasonId: PropTypes.string.isRequired,
    competitionPid: PropTypes.string,
    showBenchmarkingAflw: PropTypes.string
};
