import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import { LoggedOutModal } from './modal-elements/LoggedOutModal';
import { LoggedInModal } from './modal-elements/LoggedInModal';
import { Over18ModalWrapper } from './modal-elements/Over18ModalWrapper';
import { UpdateCommPrefModalWrapper } from './modal-elements/UpdateCommPrefModalWrapper';
import { CreateCommPrefModalWrapper } from './modal-elements/CreateCommPrefModalWrapper';

import { useSiteSettings } from 'common/react/hooks/use-site-settings';
import { getTranslation } from 'common/react/utils/translations';

let modalTypeFull;

export const SiteSettingsModal = ({ modalType, regionsPage }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const { siteSettings, setSiteSettings, settingsConfig } = useSiteSettings();

    const selectedRegion = siteSettings[settingsConfig.region.key];
    const displayWagering = !siteSettings[settingsConfig.displayWagering.key];
    const over18 = siteSettings[settingsConfig.over18.key];

    useEffect(() => {
        // Create handler here so  we can reference it in the sub and unsub call
        const authUpdateHandler = (authState) => {
            setIsAuthenticated(authState?.isAuthenticated);
        };

        fetchData(authUpdateHandler);

        // Subscribe to authState change event.
        PULSE.app.authClient.authStateManager.subscribe(authUpdateHandler);

        // Google Analytics Tracking: Initial Settings modal open
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.VIEW,
            {
                eventData: {
                    /* eslint-disable-next-line camelcase */
                    modal_name: getTranslation('settingsModal.title')
                }
            }
        );

        // Remove subscription when component is removed
        return () => {
            PULSE.app.authClient.authStateManager.unsubscribe(
                authUpdateHandler
            );
        };
    }, []);

    /**
     * Setup a callback to add to the onClick prop of the overlay. It checks if
     * it was the overlay itself that has been clicked and if we're showing
     * anything other than the over18 modal then we close the modal.
     */
    const onOverlayClick = useCallback(
        (event) => {
            if (
                event.target === event.currentTarget &&
                modalType !== 'over18'
            ) {
                PULSE.app.common.siteSettings.closeSettingsModal();
            }

            getFullModalTitle(modalType);
            PULSE.app.tracking.recordEvent(
                PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.CLOSE,
                {
                    eventData: {
                        /* eslint-disable-next-line camelcase */
                        modal_name: modalTypeFull ? modalTypeFull : modalType
                    }
                }
            );
        },
        [modalType]
    );

    return (
        <FocusTrap>
            <div className="site-settings-overlay" onClick={onOverlayClick}>
                <div
                    className={`site-settings-overlay__modal ${
                        isAuthenticated
                            ? `site-settings-overlay__modal--${modalType}`
                            : ''
                    } js-site-settings-overlay__modal`}
                >
                    {
                        /**
                         * If user is logged out
                         */
                        !isAuthenticated ? (
                            <LoggedOutModal
                                siteSettings={siteSettings}
                                setSiteSettings={setSiteSettings}
                                settingsConfig={settingsConfig}
                                selectedRegion={selectedRegion}
                                displayWagering={displayWagering}
                                regionsPage={regionsPage}
                                isOVer18Setting={over18}
                            />
                        ) : /**
                         * If user hasn't set their age
                         */
                        isAuthenticated && modalType === 'over18' ? (
                            <Over18ModalWrapper />
                        ) : /**
                         * If user hasn't set their communication preference
                         */
                        isAuthenticated && modalType === 'createComms' ? (
                            <CreateCommPrefModalWrapper />
                        ) : /**
                         * if user has requested to see the communication preferences.
                         */
                        isAuthenticated && modalType === 'updateComms' ? (
                            <UpdateCommPrefModalWrapper />
                        ) : /**
                         * User is logged in and has their age
                         * and communications preferences set
                         */
                        isAuthenticated ? (
                            <LoggedInModal
                                siteSettings={siteSettings}
                                setSiteSettings={setSiteSettings}
                                settingsConfig={settingsConfig}
                                selectedRegion={selectedRegion}
                                displayWagering={displayWagering}
                                regionsPage={regionsPage}
                                isOVer18Setting={over18}
                            />
                        ) : null
                    }
                </div>
            </div>
        </FocusTrap>
    );
};

SiteSettingsModal.propTypes = {
    modalType: PropTypes.string,
    regionsPage: PropTypes.string.isRequired
};

const fetchData = async (setIsAuthenticated) => {
    try {
        const response = await PULSE.app.authClient.isAuthenticated();
        setIsAuthenticated({ isAuthenticated: response });
    } catch (error) {
        console.error('Error fetching isAuthenticated status:', error);
    }
};

/**
 * Transforms a modal type to its full title.
 *
 * @param {string} modalTypeAbbr
 * @returns {string} The modal type's full title.
 */
const getFullModalTitle = (modalTypeAbbr) => {
    switch (modalTypeAbbr) {
        case 'updateComms':
            modalTypeFull = getTranslation(
                'settingsModal.communicationPreferences.title'
            );
            break;
        case 'settings':
            modalTypeFull = getTranslation('settingsModal.title');
            break;
        default:
            modalTypeFull = getTranslation('settingsModal.title');
    }
    return modalTypeFull;
};
