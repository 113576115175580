import React from 'react';
import PropTypes from 'prop-types';

import { ModalTitle } from './ModalTitle';
import { ModalAccount } from './ModalAccount';
import { ModalToggle } from './ModalToggle';
import { ModalLink } from './ModalLink';

import { getTranslation } from 'common/react/utils/translations';
import { ModalButton } from './ModalButton';
import { ModalNote } from './ModalNote';

export const LoggedInModal = ({
    siteSettings,
    setSiteSettings,
    settingsConfig,
    selectedRegion,
    displayWagering,
    regionsPage,
    isOVer18Setting
}) => {
    return (
        <>
            <ModalTitle
                icon="settings"
                title={getTranslation('label.settings')}
            />
            <ModalAccount />
            <ModalNote
                text={getTranslation('settingsModal.accountSettings.note')}
            />
            <ModalToggle
                icon="id-card"
                label={getTranslation('label.18OrOver')}
                isChecked={siteSettings[settingsConfig.over18.key]}
                onChange={(checked) => {
                    setSiteSettings({
                        [settingsConfig.over18.key]: checked
                    });
                    if (!checked) {
                        setSiteSettings({
                            [settingsConfig.displayWagering.key]: false
                        });
                    }
                }}
                cssClass="first-of-stack"
            />
            <ModalToggle
                icon="sportsbet"
                label={getTranslation('label.hideBettingOdds')}
                isChecked={displayWagering}
                onChange={(checked) =>
                    setSiteSettings({
                        [settingsConfig.displayWagering.key]: !checked
                    })
                }
                isDisabled={!isOVer18Setting}
                notes={getTranslation('settingsModal.bettingOdds.note')}
                cssClass="last-of-stack"
            />
            <ModalLink
                icon="globe"
                label={getTranslation('label.filters.region')}
                onClick={(event) => {
                    /**
                     * Close the modal before navigating, so it isn't open on
                     * the select region page
                     */
                    event.preventDefault();
                    PULSE.app.common.siteSettings.closeSettingsModal();
                    window.location.href = event.currentTarget.href;
                }}
                link={`${window.location.origin}${regionsPage}`}
                btnLabel={selectedRegion.name}
            />
            <ModalButton
                icon="paper-plane"
                label={getTranslation('label.comPreferences')}
                description={getTranslation(
                    'settingsModal.comPreferences.description'
                )}
                onClick={() => {
                    PULSE.app.common.siteSettings.openCommsModal();
                }}
                btnLabel={getTranslation('label.edit')}
            />
        </>
    );
};

LoggedInModal.propTypes = {
    siteSettings: PropTypes.object.isRequired,
    setSiteSettings: PropTypes.func.isRequired,
    settingsConfig: PropTypes.object.isRequired,
    selectedRegion: PropTypes.object.isRequired,
    displayWagering: PropTypes.bool.isRequired,
    regionsPage: PropTypes.string.isRequired,
    isOVer18Setting: PropTypes.bool.isRequired
};
