import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { PlayerModalVotes } from './player-modal-votes';
import { UPDATE_FAVOURITE_PLAYER } from 'store/modules/brownlow-tracker/leaderboard/actions';
import { isFavouritePlayerSelector } from 'store/modules/brownlow-tracker/leaderboard/selectors';
import { playerModalSelector } from 'store/modules/brownlow-tracker/player-modal/selectors';
import { PlayerProfileLink } from 'widgets/statspro/js/components/common/PlayerProfileLink';

/**
 * Brownlow Player Modal - Player Details section
 *
 * @param {object} props
 * @param {string} props.playerId
 *
 * @returns
 */
export const PlayerModalDetails = ({ playerId }) => {
    const dispatch = useDispatch();
    const modal = useSelector(playerModalSelector);
    const isFavourited = useSelector((state) =>
        isFavouritePlayerSelector(state, playerId)
    );

    const compAbbr = PULSE.app.common.match.getCompAbbr(modal?.seasonPid);
    const isAFLW = compAbbr.toLowerCase() === 'aflw';

    const onPlayerFavouriteClick = () => {
        dispatch(
            UPDATE_FAVOURITE_PLAYER.request({
                id: null,
                providerId: playerId
            })
        );
    };

    return (
        <div className="brownlow-player-modal__player-details-container">
            <div className="brownlow-player-modal__player-name-container">
                <span className="brownlow-player-modal__first-name">
                    {modal.player.givenName}
                </span>
                <span className="brownlow-player-modal__last-name">
                    {modal.player.surname}
                </span>
            </div>

            <div className="brownlow-player-modal__buttons-container">
                <button
                    className="brownlow-player-modal__button"
                    data-favourite-id=""
                    data-favourite-provider-id={playerId}
                    onClick={onPlayerFavouriteClick}
                >
                    {!playerId || !isFavourited ? (
                        <SvgIcon icon="pin-empty" className="icon" />
                    ) : (
                        <SvgIcon icon="pin-filled" className="icon" />
                    )}
                    {getTranslation('label.pinPlayer')}
                </button>
                {playerId && (
                    <PlayerProfileLink
                        classes="brownlow-player-modal__button"
                        player={{
                            playerId: playerId,
                            firstName: modal?.player.givenName,
                            surname: modal?.player.surname
                        }}
                        isExternal={true}
                        isAFLW={isAFLW}
                    >
                        <SvgIcon
                            icon="person"
                            folder="elements"
                            subfolder="stats"
                            className="icon"
                        />
                        {getTranslation('label.stats.playerProfile')}
                    </PlayerProfileLink>
                )}
            </div>

            <PlayerModalVotes />
        </div>
    );
};

PlayerModalDetails.propTypes = {
    playerId: PropTypes.string.isRequired
};
