import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { KEY_STATS_CATEGORIES } from '../config/categories';
import { getTranslation } from 'common/react/utils/translations';
import { KeyStatsAndPerformanceAverage } from './KeyStatsAndPerformanceAverage';
import { KeyStatsAndPerformanceTop } from './KeyStatsAndPerformanceTop';
import { KeyStatsAndPerformanceButton } from './KeyStatsAndPerformanceButton';
import { KeyStatsAndPerformanceGraph } from './KeyStatsAndPerformanceGraph';

/**
 * @param {object} props - Props.
 * @param {object} props.playerData - Player profile data.
 * @param {object} props.topSeasonData - Top season data.
 * @param {object} props.topCareerData - Top career data.
 * @param {boolean} props.showBenchmarking - To show benchmarking or not
 * @returns {JSX.Element} Component.
 */
export const KeyStatsAndPerformanceMain = ({
    playerData,
    topSeasonData,
    topCareerData,
    showBenchmarking
}) => {
    const PLAYER_POSITION = playerData.playerDetails.position;
    const ROW_ANIMATION_INTERVAL = 200;

    useEffect(() => {
        // Animate each row in one at a time
        const rows = document.querySelectorAll(
            '.js-key-stats-category-container'
        );

        let i = 0;

        const animateRows = setInterval(() => {
            rows[i].classList.add('active');
            i = i + 1;
            if (i === rows.length) {
                clearInterval(animateRows);
            }
        }, ROW_ANIMATION_INTERVAL);
    }, []);

    return (
        <>
            <div className="key-stats-and-performance__header">
                <div className="key-stats-and-performance__column-title first-column">
                    {getTranslation('label.stats.stat')}
                </div>
                <div className="key-stats-and-performance__column-title column centre-align">
                    {getTranslation('label.stats.seasonAverage')}{' '}
                    {getTranslation('label.stats.vs')}{' '}
                    {getTranslation('label.stats.careerAverage')}
                </div>
                <div className="key-stats-and-performance__column-title column">
                    {getTranslation('label.stats.topSeasonPerformance')}
                </div>
                <div className="key-stats-and-performance__column-title column">
                    {getTranslation('label.stats.topCareerPerformance')}
                </div>
            </div>
            <div className="key-stats-and-performance__body">
                {KEY_STATS_CATEGORIES[PLAYER_POSITION]?.map((category, i) => {
                    const seasonAverage =
                        playerData.seasonAverages[category]?.value;
                    const careerAverage =
                        playerData.careerAverages[category]?.value;

                    return (
                        <div
                            key={i}
                            className="key-stats-and-performance__category-stats-container js-key-stats-category-container"
                        >
                            <div className="key-stats-and-performance__category first-column">
                                {getTranslation(
                                    `label.statsLeaders.${category}.name`
                                )}
                            </div>
                            <div className="key-stats-and-performance__average-stats-wrapper column">
                                <KeyStatsAndPerformanceAverage
                                    title={getTranslation('label.stats.season')}
                                    averageValue={seasonAverage}
                                    benchmark={
                                        showBenchmarking
                                            ? playerData.seasonAverages[
                                                  category
                                              ]?.benchmark
                                            : null
                                    }
                                />
                                <KeyStatsAndPerformanceGraph
                                    seasonAverage={seasonAverage}
                                    careerAverage={careerAverage}
                                    teamAbbr={playerData.team?.teamAbbr?.toLowerCase()}
                                />
                                <KeyStatsAndPerformanceAverage
                                    title={getTranslation('label.stats.career')}
                                    averageValue={careerAverage}
                                    benchmark={
                                        showBenchmarking
                                            ? playerData.careerAverages[
                                                  category
                                              ]?.benchmark
                                            : null
                                    }
                                />
                            </div>
                            <div className="key-stats-and-performance__top-performance-wrapper double-column js-top-performance-wrapper">
                                <KeyStatsAndPerformanceButton />
                                <KeyStatsAndPerformanceTop
                                    type={'Season'}
                                    stat={topSeasonData?.[category]?.stat}
                                    teamAbbr={topSeasonData?.[
                                        category
                                    ]?.opponent?.teamAbbr?.toLowerCase()}
                                    teamName={
                                        topSeasonData?.[category]?.opponent
                                            ?.teamName
                                    }
                                    roundName={
                                        topSeasonData?.[category]?.round?.name
                                    }
                                    roundYear={null}
                                />
                                <KeyStatsAndPerformanceTop
                                    type={'Career'}
                                    stat={topCareerData?.[category]?.stat}
                                    teamAbbr={topCareerData?.[
                                        category
                                    ]?.opponent?.teamAbbr?.toLowerCase()}
                                    teamName={
                                        topCareerData?.[category]?.opponent
                                            ?.teamName
                                    }
                                    roundName={
                                        topCareerData?.[category]?.round?.name
                                    }
                                    roundYear={
                                        topCareerData?.[category]?.round?.year
                                    }
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

KeyStatsAndPerformanceMain.propTypes = {
    playerData: PropTypes.object.isRequired,
    topSeasonData: PropTypes.object,
    topCareerData: PropTypes.object,
    showBenchmarking: PropTypes.bool
};
