import React from 'react';
import PropTypes from 'prop-types';

import { SvgIcon } from 'common/react/components/SvgIcon';
import { getTranslation } from 'common/react/utils/translations';
import { useGetMappedMatchesQuery } from 'common/store/modules/api/afl-api';
import { PlayerProfileLink } from 'widgets/statspro/js/components/common/PlayerProfileLink';

const CLASS_BLOCK = 'player-stats-tooltip-link';

/**
 * @param {object} props - Props.
 * @param {object} props.player - Player.
 * @returns {JSX.Element} Component.
 */
export const TooltipLink = (props) => {
    // Get mapped Champion Match Ids to Pulse Match Ids
    const { data: matches } = useGetMappedMatchesQuery();

    const compAbbr = PULSE.app.common.match.getCompAbbr(props.seasonPid);
    const isAFLW = compAbbr.toLowerCase() === 'aflw';

    return (
        <>
            <div className={`${CLASS_BLOCK}__wrapper`}>
                {props.player.playerId && (
                    <PlayerProfileLink
                        classes={`${CLASS_BLOCK} ${
                            props.matchesPerRound.length > 1
                                ? `${CLASS_BLOCK}--full`
                                : ''
                        }`}
                        player={{
                            playerId: props.player.playerId,
                            firstName: props?.player?.playerDetails?.givenName,
                            surname: props?.player?.playerDetails?.surname
                        }}
                        isAFLW={isAFLW}
                        isExternal={true}
                    >
                        <div className={`${CLASS_BLOCK}__content`}>
                            <SvgIcon
                                className={`${CLASS_BLOCK}__icon`}
                                icon="person"
                                folder="elements"
                                subfolder="stats"
                            />
                            <span>{getTranslation('label.stats.profile')}</span>
                        </div>
                    </PlayerProfileLink>
                )}
                {props.matchesPerRound.map((match, index) => {
                    const pulseMatchId = matches?.[match.matchId];

                    return (
                        <a
                            key={match.matchId}
                            target="_blank"
                            href={
                                pulseMatchId
                                    ? `${
                                          isAFLW ? '/aflw' : ''
                                      }/matches/${pulseMatchId}`
                                    : `/matches`
                            }
                            className={`${CLASS_BLOCK}`}
                            rel="noreferrer"
                        >
                            <div className={`${CLASS_BLOCK}__content`}>
                                <SvgIcon
                                    className={`${CLASS_BLOCK}__icon`}
                                    icon="football"
                                />
                                <span>
                                    {props.matchesPerRound.length > 1
                                        ? `${getTranslation(
                                              'label.playerAwards.tooltip.match'
                                          )} ${index + 1}`
                                        : getTranslation('label.match-centre')}
                                </span>
                            </div>
                        </a>
                    );
                })}
            </div>
        </>
    );
};

TooltipLink.propTypes = {
    cell: PropTypes.object.isRequired,
    rounds: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    matchesPerRound: PropTypes.array,
    seasonPid: PropTypes.string
};
