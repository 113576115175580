import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import { SeasonLeadersCard } from './components/SeasonLeadersCard';
import { SvgIcon } from 'common/react/components/SvgIcon';
import {
    sliderSettings,
    BREAKPOINTS_FOR_FOUR_SLIDES
} from '../../config/slider';

import { useSwiperTracking } from 'common/react/hooks/use-swiper-tracking';

import { useGetLeadingPlayerStatsQuery } from 'store/modules/api/statsPro-api';

export const SeasonLeadersSlider = ({
    seasonId,
    compseasonId,
    competitionPid,
    external,
    componentName
}) => {
    const sliderName = 'season-leaders';

    const { WOMENS } = PULSE.app.common.CONSTANTS.PROVIDER_IDS;
    const isAFLW = competitionPid === WOMENS;

    const { data, isLoading } = useGetLeadingPlayerStatsQuery(seasonId);

    const { handleTransitionEnd } = useSwiperTracking(
        componentName || sliderName,
        PULSE.app.common.CONSTANTS.TRACKING.EVENTS.CAROUSEL.CONTENT_TYPE.PLAYER
    );

    return (
        <div
            className={classNames(
                'season-leaders__slider stats-slider fade-in-on-load',
                {
                    'is-loaded': !isLoading
                }
            )}
        >
            <Swiper
                {...sliderSettings(sliderName)}
                {...BREAKPOINTS_FOR_FOUR_SLIDES}
                onSlideChangeTransitionEnd={handleTransitionEnd}
            >
                {data?.displayOrder?.map((category, i) => (
                    <SwiperSlide key={i}>
                        <SeasonLeadersCard
                            players={data[category]}
                            compseasonId={compseasonId}
                            external={external}
                            isAFLW={isAFLW}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div
                className={`swiper-button-prev swiper-button-prev-unique-${sliderName}`}
            >
                <SvgIcon icon="chevron" className="icon" />
            </div>
            <div
                className={`swiper-button-next swiper-button-next-unique-${sliderName}`}
            >
                <SvgIcon icon="chevron" className="icon" />
            </div>
        </div>
    );
};

SeasonLeadersSlider.propTypes = {
    seasonId: PropTypes.string.isRequired,
    compseasonId: PropTypes.string.isRequired,
    competitionPid: PropTypes.string.isRequired,
    external: PropTypes.string.isRequired,
    componentName: PropTypes.string
};
