import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Nav } from './components/Nav';
import { NavButton } from './components/NavButton';
import { CHANGE_GLOSSARY_MODAL_VISIBILITY } from 'store/modules/statspro/glossary/actions';
import { getTranslation } from 'common/react/utils/translations';

import { Search } from '../Search';

export const Header = ({ compseason, showCompare }) => {
    const dispatch = useDispatch();
    const showGlossaryModal = () => {
        PULSE.app.tracking.recordEvent(
            PULSE.app.common.CONSTANTS.TRACKING.EVENTS.MODAL.VIEW,
            {
                eventData: {
                    /* eslint-disable-next-line camelcase */
                    modal_name: getTranslation('label.statspro.glossary')
                }
            }
        );

        return dispatch(
            CHANGE_GLOSSARY_MODAL_VISIBILITY.request({ isVisible: true })
        );
    };

    return (
        <div className="stats-header">
            <div className="stats-header__wrapper wrapper mb-0">
                <div className="stats-header__search-wrapper">
                    <Search compseason={compseason} />
                </div>
                <div className="stats-header__nav-wrapper">
                    <Nav showCompare={showCompare} />
                </div>
                <div className="stats-header__glossary-wrapper">
                    <NavButton
                        text="Glossary"
                        icon="info-circle"
                        onClick={showGlossaryModal}
                    />
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    compseason: PropTypes.string,
    showCompare: PropTypes.bool.isRequired
};
