import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { SwitchInput } from 'common/react/components/SwitchInput';
import { getTranslation } from 'common/react/utils/translations';

export const MarketingPreferences = ({
    title,
    subtitle,
    marketingPrefs,
    onToggleAllClick,
    onToggleClick,
    onSaveClick,
    hideEnableAll,
    noteTitle,
    noteText,
    sectionTitles
}) => {
    const [viewModalEventRecorded, setViewModalEventRecorded] = useState(false);

    if (!viewModalEventRecorded) {
        PULSE.app.tracking.recordEvent('view_modal', {
            eventData: {
                name: getTranslation(
                    'settingsModal.communicationPreferences.title'
                )
            }
        });
        setViewModalEventRecorded(true);
    }

    const areAllEnabled = useMemo(() => {
        return !marketingPrefs.subscriptions.some((setting) => {
            return setting.status === false;
        });
    }, [marketingPrefs.subscriptions]);

    return (
        <div className={`modal-marketing-preferences`}>
            {title ? (
                <p className="modal-marketing-preferences__title">{title}</p>
            ) : null}
            {subtitle ? (
                <p
                    className={`modal-marketing-preferences__subtitle ${
                        !sectionTitles
                            ? 'modal-marketing-preferences__subtitle--bottom-spacing'
                            : ''
                    }`}
                >
                    {subtitle}
                </p>
            ) : null}

            {marketingPrefs.preferences ? (
                <>
                    {sectionTitles ? (
                        <p className="modal-marketing-preferences__title modal-marketing-preferences__title--section-title">
                            {getTranslation(
                                'communication.preferences.preferencesTitle'
                            )}
                        </p>
                    ) : null}
                    {marketingPrefs.preferences.map((setting) => {
                        return (
                            <SwitchInput
                                id={setting.name}
                                label={setting.name.split(' ')[0]}
                                isChecked={setting.status}
                                onChange={(newValue) =>
                                    onToggleClick(setting.name, newValue)
                                }
                                key={setting.name}
                            />
                        );
                    })}
                </>
            ) : null}

            {sectionTitles ? (
                <p className="modal-marketing-preferences__title modal-marketing-preferences__title--section-title modal-marketing-preferences__title--section-title-news">
                    {getTranslation(
                        'communication.preferences.subscriptionsTitle'
                    )}
                </p>
            ) : null}

            {!hideEnableAll ? (
                <SwitchInput
                    id={'enableAll'}
                    label={getTranslation(
                        'communication.preferences.enableAll'
                    )}
                    isChecked={areAllEnabled}
                    onChange={(newValue) => {
                        if (newValue === true) {
                            onToggleAllClick(newValue);
                        }
                    }}
                    onClick={() =>
                        areAllEnabled
                            ? onToggleAllClick(false)
                            : onToggleAllClick(true)
                    }
                />
            ) : null}

            {marketingPrefs.subscriptions.map((setting) => (
                <SwitchInput
                    id={setting.name}
                    label={setting.name}
                    isChecked={setting.status}
                    onChange={(newValue) =>
                        onToggleClick(setting.name, newValue)
                    }
                    key={setting.name}
                />
            ))}

            {noteTitle ? (
                <section className="modal-marketing-preferences__note-container">
                    <p className="modal-marketing-preferences__note-title">
                        {noteTitle}
                    </p>
                    {noteText ? (
                        <p className="modal-marketing-preferences__note-text">
                            {noteText}
                        </p>
                    ) : null}
                </section>
            ) : null}

            <div className="modal-marketing-preferences__confirmation-container">
                <button
                    onClick={() => {
                        onSaveClick();
                        PULSE.app.common.siteSettings.closeSettingsModal();
                    }}
                    className="button-new button-new--primary modal-marketing-preferences-confirmation"
                >
                    {getTranslation('label.done')}
                </button>
            </div>
        </div>
    );
};

MarketingPreferences.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    marketingPrefs: PropTypes.object.isRequired,
    onToggleAllClick: PropTypes.func,
    onToggleClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    hideEnableAll: PropTypes.bool,
    sectionTitles: PropTypes.bool,
    noteTitle: PropTypes.string,
    noteText: PropTypes.string
};
