/**
 * Columns definition for the Round By Round votes table
 *
 * @param isWeekly
 * @returns {Array} Columns list
 */
export const composeColumnData = (isWeekly) => {
    let columns = [
        {
            Header: isWeekly ? 'Week' : 'Rd',
            accessor: 'roundNumber'
        },
        {
            Header: 'Opponent',
            accessor: 'opponent'
        },
        ...(!isWeekly
            ? [
                  {
                      Header: 'Predicted',
                      accessor: 'predictedPoints'
                  }
              ]
            : []),
        {
            Header: 'Votes',
            accessor: 'votes'
        }
    ];

    return columns;
};
