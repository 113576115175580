import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import {
    BREAKPOINTS_FOR_SIX_SLIDES,
    sliderSettings
} from '../../config/slider';
import { SvgIcon } from 'common/react/components/SvgIcon';
import { MatchCard } from './MatchCard';
import { Loader } from 'common/react/components/Loader';
import { useGetMatchesQuery } from 'common/store/modules/api/afl-api';
import { useSwiperTracking } from 'common/react/hooks/use-swiper-tracking';

export const MatchesSlider = ({
    anchorName,
    competitionId,
    compSeason,
    round
}) => {
    const sliderName = `video-hub-view-by-match-${anchorName}`;
    const [bullets, setBullets] = useState(null);
    const [bulletClicked, setBulletClicked] = useState(false);

    const bulletRefs = useRef([]);

    const handleClick = () => {
        setBulletClicked(true);
    };

    bullets?.forEach((node, index) => {
        bulletRefs.current[index] = node;
        node.addEventListener('click', handleClick);
    });

    const { data: matches = [] } = useGetMatchesQuery({
        competitionId: competitionId,
        compSeasonId: compSeason.id,
        roundNumber: round.roundNumber
    });

    const compAbbreviation = useMemo(
        () =>
            PULSE.app.common.match
                .getCompAbbr(matches?.[0]?.compSeason?.providerId)
                ?.toLowerCase(),
        [matches]
    );

    const { handleTransitionEnd } = useSwiperTracking(
        sliderName,
        PULSE.app.common.CONSTANTS.TRACKING.EVENTS.CAROUSEL.CONTENT_TYPE.FIXTURE
    );

    return (
        <>
            {!matches.length && (
                <div className="video-hub-view-by-match__loader">
                    <Loader />
                </div>
            )}

            <div
                className={classNames(
                    'video-hub-view-by-match__slider stats-slider fade-in-on-load',
                    { 'is-loaded': matches.length }
                )}
            >
                <Swiper
                    key={compSeason?.providerId}
                    {...sliderSettings(sliderName)}
                    {...BREAKPOINTS_FOR_SIX_SLIDES}
                    onTransitionEnd={(swiperCore) => {
                        if (bulletClicked) {
                            handleTransitionEnd(
                                swiperCore,
                                PULSE.app.common.CONSTANTS.TRACKING.EVENTS
                                    .CAROUSEL.TYPE.DOT
                            );
                        } else {
                            handleTransitionEnd(swiperCore);
                        }

                        setBulletClicked(false);
                    }}
                    onUpdate={(swiper) => {
                        setBullets(swiper.pagination.bullets);
                    }}
                >
                    {matches.map((matchItem, index) => (
                        <SwiperSlide key={index}>
                            {({ isVisible }) => (
                                <MatchCard
                                    matchData={matchItem}
                                    compAbbreviation={compAbbreviation}
                                    index={index}
                                    isVisible={isVisible}
                                />
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className={`swiper-button-prev swiper-button-prev-unique-${sliderName}`}
                >
                    <SvgIcon icon="chevron" className="icon" />
                </div>
                <div
                    className={`swiper-button-next swiper-button-next-unique-${sliderName}`}
                >
                    <SvgIcon icon="chevron" className="icon" />
                </div>
                <div className="swiper-pagination js-video-hub-view-by-match-pagination"></div>
            </div>
        </>
    );
};

MatchesSlider.propTypes = {
    anchorName: PropTypes.string.isRequired,
    competitionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    compSeason: PropTypes.object,
    round: PropTypes.object
};
