import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props - Props.
 * @param props.teamAbbreviation
 * @param props.className
 * @param props.side
 * @param props.type
 * @returns {JSX.Element} Component.
 */
export const TeamWatermarkBackground = ({
    teamAbbreviation,
    className = '',
    side = 'right',
    type = ''
}) => (
    <div
        className={`stats-team-watermark-background stats-team-watermark-background--${
            teamAbbreviation ? teamAbbreviation.toLowerCase() : 'afl'
        } stats-team-watermark-background--${side}${
            type ? `-${type}` : ''
        } ${className}`}
    >
        <div className="stats-team-watermark-background__overlay"></div>
        <div
            className="stats-team-watermark-background__watermark"
            style={{
                backgroundImage: `url('${PULSE.app.environment.watermarksPath}${
                    teamAbbreviation
                        ? `${teamAbbreviation.toLowerCase()}-${side}-dark.png`
                        : `afl-${side}-colour.png`
                }')`
            }}
        />
    </div>
);

TeamWatermarkBackground.propTypes = {
    teamAbbreviation: PropTypes.string,
    className: PropTypes.string,
    side: PropTypes.string,
    type: PropTypes.string
};
