import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CurrentRoundContext } from '../../LeaderboardTableFetcher';

import { MobileVotes } from 'widgets/brownlow-predictor-v2/js/components/MobileTable/MobileVotes';
import { getTranslation } from 'common/react/utils/translations';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { getId, getTitle } from '../../utils';

export const MobileTableVotesRow = ({
    cssClass,
    rowData,
    rounds,
    primaryTooltipComponent,
    isBrownlowV2
}) => {
    const currentRoundNumber = useContext(CurrentRoundContext);

    return (
        <div className={`${cssClass}__body-row`}>
            {isBrownlowV2 ? (
                <MobileVotes data={rowData} rounds={rounds} />
            ) : (
                <>
                    <div className={`${cssClass}__row-header`}>
                        {getTranslation('label.brownlow.predictor.votes')}
                    </div>

                    {rowData.map((cell) => {
                        const cellProps = cell.getCellProps();
                        const value = cell.value ?? 0;

                        const isUpcoming =
                            parseInt(cell.column.Header) > currentRoundNumber;

                        return (
                            /* Fake cell */
                            <div
                                {...cellProps}
                                key={cell.column.id}
                                className={classNames(
                                    !value.actual
                                        ? `${cssClass}__body-cell ${cssClass}__body-cell--empty`
                                        : `${cssClass}__body-cell`,
                                    value.actual === 'B'
                                        ? `${cssClass}__body-cell--bye`
                                        : '',
                                    value.actual === 'NP'
                                        ? `${cssClass}__body-cell--not-played`
                                        : '',
                                    isUpcoming
                                        ? `${cssClass}__body-cell--upcoming`
                                        : ''
                                )}
                            >
                                {primaryTooltipComponent &&
                                !isUpcoming &&
                                value.played &&
                                value.actual !== 'B' ? (
                                    <TooltipButton
                                        id={getId(cell, cell.row)}
                                        title={getTitle(cell, cell.row)}
                                        hideArrow
                                        noPadding
                                        externalTooltip
                                        showOnHover={false}
                                        direction="below"
                                        primaryTooltipContent={primaryTooltipComponent(
                                            cell.row,
                                            cell,
                                            null
                                        )}
                                        classNames={{
                                            component:
                                                'stats-table__cell-button-wrapper',
                                            button: 'stats-table__cell-button'
                                        }}
                                    >
                                        {value.actual}
                                    </TooltipButton>
                                ) : null}
                            </div>
                        );
                    })}
                </>
            )}
        </div>
    );
};

MobileTableVotesRow.propTypes = {
    cssClass: PropTypes.string,
    rowData: PropTypes.array,
    rounds: PropTypes.array,
    primaryTooltipComponent: PropTypes.any,
    isBrownlowV2: PropTypes.bool
};
