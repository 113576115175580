/**
 * @param {object} item - Item..
 * @param {object[]} columns - Column data.
 * @param {Array} rounds - rounds list.
 * @param { boolean } isPredictor - Different data structure when set to true
 * @param predicted
 * @param showPredictedData
 * @returns {object[]} Row data for the given player.
 */
export const composeRowData = (
    item,
    columns,
    rounds,
    isPredictor = false,
    predicted
) => {
    const byeRounds =
        rounds
            ?.filter((round) =>
                round.byes?.some((bye) => bye.providerId === item?.team.teamId)
            )
            ?.map((round) => round.roundNumber) ?? [];

    const predictedVotes = predicted?.find(
        (predictedItem) =>
            item.player.playerId === predictedItem.providerId ||
            item.player.providerId === predictedItem.providerId
    );

    return {
        player: {
            eligible: item?.eligible ?? false,
            givenName: isPredictor
                ? item?.player.firstName ?? ''
                : item?.player.givenName ?? '',
            surname: item?.player.surname ?? '',
            photoURL: item?.player.photoURL ?? '',
            playerId: isPredictor
                ? item?.player.providerId ?? ''
                : item?.player.playerId ?? '',
            winner: item?.player.winner ?? false,
            position: item?.position ?? null
        },
        ...columns.reduce((prev, curr) => {
            let roundData = {
                actual: '',
                predicted: '',
                played: true,
                matches: []
            };

            if (
                byeRounds.includes(parseInt(curr.accessor.replace('round', '')))
            ) {
                roundData.actual = 'B';
            } else {
                item?.roundByRoundTotalVotes?.forEach((round) => {
                    if (curr.accessor === 'round' + round.roundNumber) {
                        roundData.actual = round.votes;
                        return;
                    }
                });

                item?.roundByRoundVotes?.forEach((round) => {
                    if (curr.accessor === 'round' + round.roundNumber) {
                        roundData.matches = round.matches;
                        return;
                    }
                });
            }

            if (predictedVotes) {
                Object.keys(predictedVotes.rounds).forEach((key) => {
                    if (
                        parseInt(curr.accessor.replace('round', '')) ===
                        parseInt(key)
                    ) {
                        const predictedData = predictedVotes.rounds[key];

                        if (roundData.matches.length) {
                            let predictedSum = 0;

                            predictedData.forEach((predictedVote) => {
                                roundData.matches.forEach((match) => {
                                    if (
                                        match.matchId ===
                                        predictedVote.providerId
                                    ) {
                                        match.played = predictedVote.played;
                                        match.points = predictedVote.points;
                                        match.bye = predictedVote.bye;

                                        predictedSum += parseInt(
                                            predictedVote.points ?? 0
                                        );
                                    }
                                });
                            });

                            const playedInTheRound =
                                roundData.matches.filter(
                                    (match) =>
                                        match.played !== false &&
                                        match.bye !== true
                                )?.length > 0;

                            if (!playedInTheRound) {
                                roundData.played = false;
                                roundData.actual = 'NP';
                            } else {
                                roundData.predicted =
                                    predictedSum > 0 ? predictedSum : '';
                            }
                        }
                    }
                });
            }

            return {
                ...prev,
                [curr.accessor]: roundData
            };
        }, {}),
        totalVotes: item?.totalVotes ?? 0,
        bettingOdds: item?.bettingOdds ?? 0,
        totalPredictedVotes: predictedVotes?.totalVotes ?? 0
    };
};
