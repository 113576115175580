import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

/**
 * A UI component replicating the media thumbnail markup as data is fetched.
 *
 * @returns {JSX.Element} The Skeleton Loader.
 */
export const MarketingPreferencesSkeleton = () => {
    return (
        <div className="modal-marketing-preferences__item-skeleton">
            <div className="modal-marketing-preferences__item-skeleton--description">
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--description-title"
                    height={24}
                    width={270}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--description-text"
                    height={40}
                    width={390}
                />
            </div>
            <div className="modal-marketing-preferences__item-skeleton-content">
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--content-title"
                    height={24}
                    width={110}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
            </div>
            <div className="modal-marketing-preferences__item-skeleton-content">
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--content-title"
                    height={24}
                    width={110}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
                <Skeleton
                    className="modal-marketing-preferences__item-skeleton--switch-input"
                    height={56}
                />
            </div>
        </div>
    );
};
