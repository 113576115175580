/**
 * Sort Favourites
 *
 * Sorts the leaderboard data, by adding the favourites to the front
 * of the data array
 *
 * @param {*} array - Input list of players
 * @param {*} favouritesArray - Array of a users favourite players
 * @param {*} teamFilterId - The providerId of any active team filter
 * @returns {Array} An array of players, sorted by the leader then favourites
 */
export const sortFavourites = (array, favouritesArray, teamFilterId) => {
    /**
     * If there are winners and no team filter
     * or
     * If there are winners and team filter, and the winners are part of that team
     * then remove them so they can be placed at the front of the sorted array.
     */
    let winners = array?.filter(
        (item) =>
            item.player.winner === true &&
            ((teamFilterId && item.team.teamId === teamFilterId) ||
                teamFilterId === '')
    );

    const removedWinners = array?.filter((item) => !winners.includes(item));

    // Split favourites and remaining players into their own array
    const favouritePlayers = [];
    const remainingPlayers = removedWinners.filter((player) => {
        if (
            favouritesArray.some(
                (favourite) =>
                    favourite.providerId === player.player.playerId ||
                    favourite.providerId === player.player.providerId
            )
        ) {
            favouritePlayers.push(player);
            return false;
        }

        return true;
    });

    /**
     * Construct the full list with winner first (if there is one), then
     * favourites and then the rest
     */
    if (winners) {
        return [...winners, ...favouritePlayers, ...remainingPlayers];
    }

    return [...favouritePlayers, ...remainingPlayers];
};
