import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getTranslation } from 'common/react/utils/translations';
import {
    PlayerModalDataSelector,
    playerModalSelector
} from 'store/modules/brownlow-tracker/player-modal/selectors';
import { PlayerPhoto } from 'common/react/components/PlayerPhoto';

export const PlayerModalPhoto = () => {
    const playerModalData = useSelector(playerModalSelector);
    // eslint-disable-next-line no-magic-numbers
    const seasonYear = playerModalData.seasonPid.substr(4, 4);
    const data = useSelector(PlayerModalDataSelector);

    const compAbbreviation = PULSE.app.common.match
        .getCompAbbr(data?.seasonId)
        ?.toLowerCase();

    const playerVotesData = useMemo(() => {
        return data?.players?.find(
            (item) => item.player.playerId === playerModalData.playerId
        );
    }, [playerModalData, data]);

    return (
        <div className="brownlow-player-modal__player-headshot-container">
            <div className="brownlow-player-modal__player-headshot-inner-container">
                <div
                    className={`brownlow-player-modal__player-headshot-photo-container ${playerVotesData?.team.teamAbbr.toLowerCase()}`}
                >
                    <div
                        className="brownlow-player-modal__player-headshot-watermark"
                        style={{
                            backgroundImage: `url('${
                                PULSE.app.environment.watermarksPath
                            }${playerVotesData?.team.teamAbbr.toLowerCase()}-right-dark.png')`
                        }}
                    ></div>
                    <PlayerPhoto
                        className="brownlow-player-modal__photo"
                        photoUrl={playerVotesData?.player.photoURL}
                        scale="0.5"
                        alt={`${getTranslation('label.stats.headshot.alt')} ${
                            playerVotesData?.player.givenName
                        } ${playerVotesData?.player.surname}`}
                    />
                </div>
            </div>

            {playerVotesData?.winner ? (
                <div className="brownlow-player-modal__player-headshot-ribbon">
                    <span className="brownlow-player-modal__player-headshot-ribbon-text">
                        <span>{seasonYear}</span>{' '}
                        {compAbbreviation === 'aflw'
                            ? getTranslation(
                                  'label.playerAwards.tracker.bestAndFairestMedallist'
                              )
                            : getTranslation(
                                  'label.brownlow.tracker.brownlowMedallist'
                              )}
                    </span>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
