import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { MatchCard } from './match-card';
import { brownlowMatchSelector } from 'store/modules/brownlow-tracker/match-by-match/selectors';
import { useGetBFAwardsLeaderboardQuery } from 'common/store/modules/api/cfs-api';

export const MatchCardVoteFetcher = ({
    matchData,
    seasonPid,
    seasonId,
    modifier,
    playerModalEnabled,
    shouldHidePredicted
}) => {
    const matchVotes = useSelector((state) =>
        brownlowMatchSelector(state, seasonPid, matchData.providerId)
    );

    const { data: leaderboardData } = useGetBFAwardsLeaderboardQuery({
        seasonId: seasonPid
    });

    return (
        <MatchCard
            voteData={matchVotes}
            matchData={matchData}
            seasonPid={seasonPid}
            seasonId={seasonId}
            modifier={modifier}
            playerModalEnabled={playerModalEnabled}
            leaderboardData={leaderboardData}
            shouldHidePredicted={shouldHidePredicted}
        />
    );
};

MatchCardVoteFetcher.propTypes = {
    matchData: PropTypes.object,
    seasonPid: PropTypes.string,
    seasonId: PropTypes.number,
    modifier: PropTypes.string,
    playerModalEnabled: PropTypes.bool,
    shouldHidePredicted: PropTypes.bool
};
