import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGetBrownlowPredictorQuery } from 'common/store/modules/api/afl-api';
import { SeasonContext } from '../Leaderboard/components/table/LeaderboardTableFetcher';
import { TeamIcon } from 'common/react/components/TeamIcon';

const CLASS_BLOCK = 'player-stats-tooltip-main-stat';

/**
 * @param {object} props - Props.
 * @param {object} props.row - Row.
 * @param {object} props.cell - Cell.
 * @returns {JSX.Element} Component.
 */
export const TooltipVotes = (props) => {
    const season = useContext(SeasonContext);
    const [playerPulseId, setPlayerPulseId] = useState([]);

    /**
     * Getting Pulse Player id
     */
    useEffect(() => {
        if (props?.player?.playerId) {
            PULSE.app.common.pulseId
                .getPlayerPulseId(props.player.playerId)
                .then((id) => setPlayerPulseId([id]));
        }
    }, [props]);

    const { data: players } = useGetBrownlowPredictorQuery(
        { compSeasonId: season?.id, page: 0, players: playerPulseId },
        {
            skip: !(season?.id && playerPulseId.length)
        }
    );

    const compAbbr = PULSE.app.common.match.getCompAbbr(props?.seasonPid);

    const roundNumber = props?.cell?.column?.id?.replace('round', '');
    const playerId = props?.player?.playerId;

    const foundPlayer = players?.results?.find((round) => {
        return round.providerId === playerId;
    });

    const playerPredictedVoteRounds = foundPlayer?.rounds?.[roundNumber];

    return (
        <div className={CLASS_BLOCK}>
            <div className={`${CLASS_BLOCK}__header-row`}>
                <div>
                    <div className={`${CLASS_BLOCK}__name`}>
                        {roundNumber === '0'
                            ? 'OR Votes'
                            : `${
                                  compAbbr === 'AFLW' ? 'Week' : 'Round'
                              } ${roundNumber} Votes`}
                    </div>
                    <div className={`${CLASS_BLOCK}__row`}>
                        <div className={`${CLASS_BLOCK}__value`}>
                            {props.cell?.value?.actual || '0'}
                        </div>
                    </div>
                </div>
                {props.matchesPerRound?.map((match) => {
                    const opp = match.opponent.teamAbbr;
                    return (
                        <div
                            key={match.matchId}
                            className={`${CLASS_BLOCK}__opponent`}
                        >
                            v {opp}
                            <TeamIcon
                                teamAbbreviation={opp}
                                className={`${CLASS_BLOCK}__opponent-logo`}
                            />
                        </div>
                    );
                })}
            </div>

            {playerPredictedVoteRounds?.length &&
                props.matchesPerRound.length &&
                props.matchesPerRound.map((match) => {
                    const foundMatch = playerPredictedVoteRounds.find(
                        (predMatch) => predMatch.providerId === match.matchId
                    );
                    if (foundMatch) {
                        return (
                            <div
                                key={match.matchId}
                                className={`${CLASS_BLOCK}__row`}
                            >
                                <div className={`${CLASS_BLOCK}__predicted`}>
                                    {`${PULSE.I18N.lookup(
                                        'label.brownlow.tracker.predicted'
                                    )}: ${foundMatch.points}`}
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
        </div>
    );
};

TooltipVotes.propTypes = {
    row: PropTypes.object,
    cell: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    matchesPerRound: PropTypes.array,
    seasonPid: PropTypes.string
};
