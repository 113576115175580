/* eslint-disable react/jsx-key */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BENCHMARKING } from 'common/react/components/benchmarking/config/benchmarking';
import { TooltipButton } from 'common/react/components/tooltip-button';
import { StatsTablePredictedCell } from './StatsTablePredictedCell';
import { CurrentRoundContext } from 'widgets/player-awards-tracker/js/components/Leaderboard/components/table/LeaderboardTableFetcher';
import { PredictorCurrentRoundContext } from 'widgets/brownlow-predictor-v2/js/components/BrownlowPredictor';
import { getTranslation } from 'common/react/utils/translations';

const TABLE_COLS = 7;

export const StatsTableBody = ({
    rows,
    getTableBodyProps,
    prepareRow,
    getBenchmark,
    primaryTooltipComponent,
    getCellId,
    getCellTitle,
    customCells,
    skipCells,
    valueWrapper,
    isProspect,
    showPredictedData,
    isBrownlowV2,
    isBrownlowTracker,
    getTrProps = () => null
}) => {
    const isProspectTable = isProspect ?? false;

    const PredictorCurrentRound = useContext(PredictorCurrentRoundContext);
    const LeaderboardCurrentRound = useContext(CurrentRoundContext);

    const currentRoundNumber = PredictorCurrentRound
        ? PredictorCurrentRound
        : LeaderboardCurrentRound;

    return (
        <tbody {...getTableBodyProps()}>
            {!rows.length ? (
                <tr className="stats-table__body-row">
                    <td className="stats-table__cell" colSpan={TABLE_COLS}>
                        {getTranslation('label.search.noResultsFound')}
                    </td>
                </tr>
            ) : (
                rows.map((row, rowIndex) => {
                    prepareRow(row);

                    const isQualiferRow =
                        rowIndex === row?.original?.qualiferRow
                            ? 'is-qualifier-row'
                            : '';

                    const isConferenceRow =
                        row?.original?.isConference && rowIndex === 1
                            ? 'is-conference-row'
                            : '';

                    const isLiveRow = row?.values?.isPlaying ? 'is-live' : '';

                    const cells = row.cells.filter((cell) => {
                        if (!skipCells) {
                            return true;
                        }
                        return !skipCells.includes(cell.column.id);
                    });

                    return (
                        <tr
                            {...row.getRowProps()}
                            className={`stats-table__body-row ${isQualiferRow} ${isConferenceRow} ${isLiveRow}`}
                            {...getTrProps(row)}
                            key={rowIndex}
                        >
                            {cells.map((cell, cellIndex) => {
                                const cellProps = cell.getCellProps();

                                if (
                                    customCells &&
                                    customCells[cell.column.id]
                                ) {
                                    return customCells[cell.column.id](
                                        row,
                                        cell,
                                        cellProps,
                                        rowIndex
                                    );
                                }

                                const benchmark = getBenchmark
                                    ? getBenchmark(row, cell)
                                    : null;

                                if (
                                    typeof cell.value === 'undefined' &&
                                    isProspectTable
                                ) {
                                    return null;
                                }

                                const isUpcoming =
                                    parseInt(cell.column.Header) >
                                    currentRoundNumber;

                                const value = cell.value ?? 0;

                                // if either a brownlow tracker or predictor widget
                                if (isBrownlowV2 || isBrownlowTracker) {
                                    return (
                                        <StatsTablePredictedCell
                                            key={cellIndex}
                                            cellProps={cellProps}
                                            condition={valueWrapper}
                                            value={value}
                                            row={row}
                                            isUpcoming={isUpcoming}
                                            showPredictedData={
                                                showPredictedData
                                            }
                                            tooltipButton={
                                                primaryTooltipComponent &&
                                                !isUpcoming &&
                                                value.played &&
                                                value.actual !== 'B' ? (
                                                    <TooltipButton
                                                        id={getCellId(
                                                            row,
                                                            cell
                                                        )}
                                                        title={getCellTitle(
                                                            row,
                                                            cell
                                                        )}
                                                        hideArrow
                                                        noPadding
                                                        externalTooltip
                                                        showOnHover={false}
                                                        direction="below"
                                                        primaryTooltipContent={primaryTooltipComponent(
                                                            row,
                                                            cell,
                                                            benchmark
                                                        )}
                                                        classNames={{
                                                            component:
                                                                'stats-table__cell-button-wrapper',
                                                            button: 'stats-table__cell-button'
                                                        }}
                                                    >
                                                        {value.actual}
                                                    </TooltipButton>
                                                ) : null
                                            }
                                        />
                                    );
                                }
                                return (
                                    <td
                                        {...cellProps}
                                        className={classnames(
                                            'stats-table__cell',
                                            {
                                                'stats-table__cell--sorted':
                                                    cell.column.isSorted,
                                                'stats-table__cell--elite':
                                                    benchmark ===
                                                    BENCHMARKING.ELITE.KEY,
                                                'stats-table__cell--above-avg':
                                                    benchmark ===
                                                    BENCHMARKING.ABOVE_AVERAGE
                                                        .KEY,
                                                'stats-table__cell--avg':
                                                    benchmark ===
                                                    BENCHMARKING.AVERAGE.KEY,
                                                'stats-table__cell--below-avg':
                                                    benchmark ===
                                                    BENCHMARKING.BELOW_AVERAGE
                                                        .KEY
                                            }
                                        )}
                                        key={cellIndex}
                                    >
                                        <ConditionalWrapper
                                            condition={valueWrapper}
                                            wrapper={(children) => (
                                                <div
                                                    className={classnames(
                                                        'stats-table__cell-inner-wrapper',
                                                        {
                                                            'stats-table__cell-inner-wrapper--empty':
                                                                !value
                                                        },
                                                        {
                                                            'stats-table__cell-inner-wrapper--bye':
                                                                value === 'B' &&
                                                                !isUpcoming
                                                        },
                                                        {
                                                            'stats-table__cell-inner-wrapper--not-played':
                                                                value ===
                                                                    'NP' &&
                                                                !isUpcoming
                                                        },
                                                        {
                                                            'stats-table__cell-inner-wrapper--upcoming':
                                                                isUpcoming
                                                        }
                                                    )}
                                                >
                                                    {children}
                                                </div>
                                            )}
                                        >
                                            {primaryTooltipComponent &&
                                            !isUpcoming &&
                                            value !== 'NP' &&
                                            value !== 'B' ? (
                                                <TooltipButton
                                                    id={getCellId(row, cell)}
                                                    title={getCellTitle(
                                                        row,
                                                        cell
                                                    )}
                                                    hideArrow
                                                    noPadding
                                                    externalTooltip
                                                    showOnHover={false}
                                                    direction="below"
                                                    primaryTooltipContent={primaryTooltipComponent(
                                                        row,
                                                        cell,
                                                        benchmark
                                                    )}
                                                    classNames={{
                                                        component:
                                                            'stats-table__cell-button-wrapper',
                                                        button: 'stats-table__cell-button'
                                                    }}
                                                >
                                                    {value}
                                                </TooltipButton>
                                            ) : (
                                                <>
                                                    {value !== 'NP' &&
                                                        !isUpcoming &&
                                                        value}
                                                </>
                                            )}
                                        </ConditionalWrapper>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })
            )}
        </tbody>
    );
};

StatsTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
    prepareRow: PropTypes.func.isRequired,
    getBenchmark: PropTypes.func,
    primaryTooltipComponent: PropTypes.func,
    getCellId: PropTypes.func,
    getCellTitle: PropTypes.func,
    customCells: PropTypes.object,
    skipCells: PropTypes.array,
    valueWrapper: PropTypes.bool,
    isProspect: PropTypes.bool,
    getTrProps: PropTypes.func,
    showPredictedData: PropTypes.bool,
    isBrownlowV2: PropTypes.bool,
    isBrownlowTracker: PropTypes.bool
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
