import React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslation } from 'common/react/utils/translations';
import { FETCH_PREDICTED_DATA } from 'store/modules/brownlow-tracker/player-modal/actions';
import {
    PlayerModalDataSelector,
    PlayerModalPredictedSelector,
    playerModalSelector
} from 'store/modules/brownlow-tracker/player-modal/selectors';

export const PlayerModalVotes = () => {
    const dispatch = useDispatch();
    const playerModalData = useSelector(playerModalSelector);
    // eslint-disable-next-line no-magic-numbers
    const seasonYear = playerModalData.seasonPid.substr(4, 4);
    const data = useSelector(PlayerModalDataSelector);
    const predictedData = useSelector(PlayerModalPredictedSelector);

    const playerVotesData = useMemo(() => {
        return playerModalData.playerId
            ? data?.players?.find(
                  (item) => item.player.playerId === playerModalData.playerId
              )
            : null;
    }, [playerModalData, data]);

    useEffect(() => {
        // Fetch predicted data
        if (
            playerVotesData?.player &&
            (!predictedData ||
                (predictedData?.players?.length &&
                    !predictedData.players.find(
                        (item) => item.providerId === playerModalData.playerId
                    )))
        ) {
            dispatch(
                FETCH_PREDICTED_DATA.request({
                    season: playerModalData.seasonId,
                    playerName:
                        playerVotesData?.player.givenName +
                        ' ' +
                        playerVotesData?.player.surname
                })
            );
        }
    }, [playerVotesData]);

    return (
        <div className="brownlow-player-modal__votes-container">
            <div className="brownlow-player-modal__current-votes">
                <span className="brownlow-player-modal__label brownlow-player-modal__label--full-width">
                    <span>{seasonYear}</span>
                    {' ' + getTranslation('label.votes')}
                </span>
                <span className="brownlow-player-modal__votes-count">
                    {playerModalData.playerId
                        ? playerVotesData?.votes ?? '-'
                        : ''}
                </span>
            </div>
            <div
                className={`brownlow-player-modal__other-votes ${
                    playerModalData?.shouldHidePredicted
                        ? 'brownlow-player-modal__other-votes--one-item'
                        : ''
                }`}
            >
                <div className="brownlow-player-modal__other-votes-content">
                    <span className="brownlow-player-modal__label">
                        {getTranslation('label.brownlow.tracker.position')}
                    </span>
                    <span className="brownlow-player-modal__votes-position">
                        {playerModalData.playerId &&
                        playerModalData.player.position
                            ? PULSE.app.common.numberHelper.ordinalSuffix(
                                  playerModalData.player.position
                              )
                            : '-'}
                    </span>
                </div>
                {!playerModalData?.shouldHidePredicted && (
                    <div className="brownlow-player-modal__other-votes-content">
                        <span className="brownlow-player-modal__label">
                            {getTranslation(
                                'label.brownlow.tracker.predictedVotes'
                            )}
                        </span>
                        <span className="brownlow-player-modal__votes-position">
                            {predictedData?.players?.find(
                                (item) =>
                                    item.providerId === playerModalData.playerId
                            )?.totalVotes ?? '-'}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};
