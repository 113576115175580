import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from 'common/react/components/Chip';
import { getTranslation } from 'common/react/utils/translations';

export const PlayerAwardsLeaderboardTooltip = ({
    shouldHidePredicted,
    seasonPid
}) => {
    const compAbbr = PULSE.app.common.match.getCompAbbr(seasonPid) || '';

    const infoText = getTranslation(
        `label.playerAwards.tracker.tooltipText.${compAbbr.toLowerCase()}`
    );

    return (
        <div className="chip__list-wrapper">
            <div className="chip__list">
                <Chip
                    bgColour="linear-gradient(180deg, #F3D696 0%, #CFA95A 100%)"
                    symbolColour="rgb(0 0 0)"
                    symbol="●"
                    label={getTranslation('label.leaderVotesReceived')}
                />
                <Chip
                    bgColour="var(--fill-600)"
                    symbolColour="rgb(255 255 255)"
                    symbol="●"
                    label={getTranslation('label.votesReceived')}
                />
                {!shouldHidePredicted && (
                    <Chip
                        bgColour="rgb(0 64 153 / 8%)"
                        symbolColour="rgb(0 64 153)"
                        symbol="●"
                        label={getTranslation('label.votesPredicted')}
                    />
                )}
                <Chip
                    bgColour="rgb(153 153 153)"
                    symbolColour="rgb(255 255 255)"
                    symbol="●"
                    label={getTranslation('label.ineligible')}
                />
                <Chip
                    bgColour="rgb(0 0 0 / 6%)"
                    label={getTranslation('label.noVotes')}
                />
                <Chip
                    bgColour="rgb(0 0 0 / 12%)"
                    label={getTranslation('label.upcoming')}
                    hollow
                />
                <Chip
                    bgColour="rgb(0 0 0 / 0%)"
                    symbolColour="rgb(0 0 0 / 12%)"
                    symbol="-"
                    label={getTranslation('label.didNotPlay')}
                />
                {compAbbr !== 'AFLW' && (
                    <Chip
                        bgColour="rgb(0 0 0 / 6%)"
                        symbolColour="rgb(118 118 118)"
                        symbol="B"
                        label={getTranslation('label.fixtures.group.title.bye')}
                        className="chip__square-symbol--large"
                    />
                )}
            </div>
            {infoText && (
                <div
                    className="chip__info-text"
                    dangerouslySetInnerHTML={{ __html: infoText }}
                ></div>
            )}
        </div>
    );
};

PlayerAwardsLeaderboardTooltip.propTypes = {
    shouldHidePredicted: PropTypes.bool,
    seasonPid: PropTypes.string
};
